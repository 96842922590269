import {
    fetchMonitorProvaHoraria,
    fetchMonitorProvaHorariaFiltros,
} from '@/api/business/provaHoraria'
import {
    FetchMonitorProvaHorariaFiltrosProps,
    FetchMonitorProvaHorariaProps,
} from '@/api/business/provaHoraria/type'
import { getDate } from '@/utils/date'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const provaHorariaKeys = {
    all: ['provaHoraria'] as const,
    lists: () => [...provaHorariaKeys.all, 'list'] as const,
    list: ({
        cod_filial,
        data_final,
        data_inicial,
        cod_ponto_de_coleta,
    }: FetchMonitorProvaHorariaProps) =>
        [
            ...provaHorariaKeys.lists(),
            cod_filial,
            data_final,
            data_inicial,
            cod_ponto_de_coleta,
        ] as const,
    filters: ({ cod_filial }: FetchMonitorProvaHorariaFiltrosProps) =>
        [...provaHorariaKeys.lists(), 'filters', cod_filial] as const,
}

export type UseProvaHorariaQueryProps = FetchMonitorProvaHorariaProps & {
    enabled: boolean
}
export type UseProvaHorariaFiltroQueryProps =
    FetchMonitorProvaHorariaFiltrosProps & {
        enabled: boolean
    }

export const useMonitorProvaHorariaQuery = ({
    enabled,
    ...props
}: UseProvaHorariaQueryProps) => {
    return useQuery({
        queryKey: provaHorariaKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorProvaHoraria({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) => {
            const flatResults = data.flatMap((result) => result.resultados)

            return data
                .map((value) => {
                    const children = flatResults.filter(
                        (result) => result.cod_amostra_pai === value.cod_amostra
                    )

                    const firstResult = value.resultados[0]

                    return {
                        ...value,
                        resultados: value.resultados
                            .filter((result) => !result.cod_amostra_pai)
                            .concat(
                                children.map((child) => ({
                                    ...child,
                                    skip_lote: firstResult?.skip_lote,
                                }))
                            ),
                    }
                })
                .filter((value) => value.resultados.length > 0)
                .sort((a, b) => {
                    const aDate = getDate({
                        date: a.data_da_coleta,
                        hour: a.hora_da_coleta,
                    })
                    const bDate = getDate({
                        date: b.data_da_coleta,
                        hour: b.hora_da_coleta,
                    })

                    const dateComparison = bDate.getTime() - aDate.getTime()

                    if (dateComparison !== 0) {
                        return dateComparison
                    }

                    return b.cod_skip_lote - a.cod_skip_lote
                })
        },
    })
}
export const useMonitorProvaHorariaFiltrosQuery = ({
    enabled,
    ...props
}: UseProvaHorariaFiltroQueryProps) => {
    return useQuery({
        queryKey: provaHorariaKeys.filters({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorProvaHorariaFiltros({ ...props }),
        placeholderData: keepPreviousData,
    })
}
