import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyMuted } from '@/components/ui/typography'

type LinhasSelectProps = {
    linhas: { linha: string }[]
    value: string
    onChange: (value: string) => void
}

const LinhasSelect = ({ linhas, value, onChange }: LinhasSelectProps) => {
    return (
        <div className="space-y-1.5">
            <TypographyMuted>Centro de custo</TypographyMuted>
            <Select onValueChange={onChange} value={value}>
                <SelectTrigger className="gap-4 p-4 text-sm w-[250px] hover:bg-accent">
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    {linhas.map((linha) => {
                        return (
                            <SelectItem key={linha.linha} value={linha.linha}>
                                {linha.linha}
                            </SelectItem>
                        )
                    })}
                </SelectContent>
            </Select>
        </div>
    )
}

export default LinhasSelect
