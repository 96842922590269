import instance from '..'
import {
    FetchMonitorArrolhamentoFiltrosProps,
    FetchMonitorArrolhamentoProps,
    MonitorArrolhamento,
    MonitorArrolhamentoFiltros,
} from './type'

export const fetchMonitorArrolhamento = async (
    props: FetchMonitorArrolhamentoProps
) => {
    const response = await instance.post<MonitorArrolhamento[]>(
        '/monitor/envase/arrolhamento',
        {
            ...props,
        }
    )

    return response.data
}

export const fetchMonitorArrolhamentoFiltros = async (
    props: FetchMonitorArrolhamentoFiltrosProps
) => {
    const response = await instance.post<MonitorArrolhamentoFiltros[]>(
        '/monitor/recursos/arrolhamento',
        {
            ...props,
        }
    )

    return response.data
}
