import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorLubrificanteDeEsteira } from '@/api/business/lubrificanteDeEsteira/type'
import CellPopover from './components/CellPopover'

type LubrificanteDeEsteiraTableProps = {
    turno: string
    data: MonitorLubrificanteDeEsteira[]
}

const LubrificanteDeEsteiraTable = ({
    turno,
    data,
}: LubrificanteDeEsteiraTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const tableData = useMemo(() => data, [data])

    type LubrificanteDeEsteiraTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<LubrificanteDeEsteiraTableData>()

    const columns = useMemo(
        () => [
            columnHelper.group({
                id: turno,
                header: turno,
                size: 220,
                enableHiding: false,
                meta: {
                    cell: { className: 'p-3' },
                },
                columns: [
                    columnHelper.accessor('bem', {
                        id: 'bem',
                        header: 'Ponto de coleta',
                        size: 420,
                    }),
                    columnHelper.accessor('produto', {
                        id: 'produto',
                        header: 'Produto',
                        size: 350,
                    }),
                    columnHelper.accessor('data_da_coleta', {
                        id: 'data_da_coleta',
                        header: 'Data coleta',
                    }),
                    columnHelper.accessor('hora_da_coleta', {
                        id: 'hora_da_coleta',
                        header: 'Hora coleta',
                    }),
                    columnHelper.display({
                        id: 'concentracao',
                        header: 'Concentração',
                        cell: ({ row }) => {
                            const { resultados } = row.original

                            return resultados.map((result) => (
                                <CellPopover data={result} />
                            ))
                        },
                        meta: {
                            cell: {
                                className: 'p-0',
                            },
                        },
                    }),
                ],
            }),
        ],
        []
    )

    return (
        <Table<LubrificanteDeEsteiraTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            virtualizeRows={false}
            className="h-auto overflow-visible"
            meta={{ layout: 'stretch' }}
        />
    )
}

export default LubrificanteDeEsteiraTable
