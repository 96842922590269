import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactFlow, {
    Background,
    ConnectionLineType,
    Controls,
    Edge,
    // MiniMap,
    Node,
    OnConnect,
    Panel,
    Position,
    addEdge,
    useEdgesState,
    useNodesState,
} from 'reactflow'
import 'reactflow/dist/style.css'
import CustomNode from './components/CustomNode'
import dagre from 'dagre'
import {
    MonitorRastreabilidade,
    RastreabilidadeAninhada,
} from '@/api/business/rastreabilidade/type'
import { Link, useLocation } from 'react-router-dom'
import { ROUTES } from '@/consts/routes'
import { Loader, MoveLeft, TriangleAlert, X } from 'lucide-react'
import { TypographyP } from '@/components/ui/typography'
import { AnimatePresence, motion } from 'framer-motion'
import CustomEdge from './components/CustomEdge'

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const nodeWidth = 200
const nodeHeight = 64

const getLayoutedElements = (
    nodes: RastreabilidadeNode[],
    edges: Edge[],
    direction = 'LR'
) => {
    const isHorizontal = direction === 'LR'
    dagreGraph.setGraph({ rankdir: direction })

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, {
            width: node.width || nodeWidth,
            height: nodeHeight,
        })
    })

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target)
    })

    dagre.layout(dagreGraph)

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id)
        node.targetPosition = isHorizontal ? Position.Left : Position.Top
        node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom

        node.position = {
            x: nodeWithPosition.x - (node.width || nodeWidth) / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        }

        return node
    })

    return { nodes, edges }
}

export type NodeData = RastreabilidadeAninhada | MonitorRastreabilidade
export type EdgeData = RastreabilidadeAninhada | MonitorRastreabilidade

export type RastreabilidadeNode = Node<NodeData>

type FlowProps = {
    initialNodes: RastreabilidadeNode[]
    initialEdges: Edge[]
    isPending?: boolean
    isFetching?: boolean
    isError?: boolean
}

const nodeTypes = { defaultNode: CustomNode }
const edgeTypes = { defaultEdge: CustomEdge }

type TopCenterPannelProps = {
    children?: React.ReactNode
}

const TopCenterPannel = ({ children }: TopCenterPannelProps) => {
    return (
        <Panel position="top-center">
            <motion.div
                initial={{
                    y: 0,
                }}
                animate={{
                    y: 5,
                }}
                exit={{
                    y: 0,
                }}
                className="flex items-center gap-2 px-4 py-2 border rounded-full shadow bg-muted"
            >
                {children}
            </motion.div>
        </Panel>
    )
}

const Flow = ({
    initialNodes,
    initialEdges,
    isError,
    isPending,
    isFetching,
}: FlowProps) => {
    const [isFrom, setIsFrom] = useState(false)
    const { state } = useLocation()

    const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(
        () => getLayoutedElements(initialNodes, initialEdges),
        [initialEdges, initialNodes]
    )

    const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes)
    const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges)

    const onConnect: OnConnect = useCallback(
        (params) =>
            setEdges((eds) =>
                addEdge(
                    {
                        ...params,
                        type: ConnectionLineType.SmoothStep,
                        animated: true,
                    },
                    eds
                )
            ),
        []
    )

    useEffect(() => {
        setNodes(initialNodes)
    }, [initialNodes])

    useEffect(() => {
        setEdges(initialEdges)
    }, [initialEdges])

    useEffect(() => {
        if (state && state.from === ROUTES.PRODUTO_ACABADO.index) {
            setIsFrom(true)
        }
    }, [])

    return (
        <div className="w-full h-full">
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                connectionLineType={ConnectionLineType.SmoothStep}
                fitView
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
            >
                <AnimatePresence>
                    {isError && (
                        <TopCenterPannel>
                            <X size={14} className="text-red-500" />
                            <TypographyP>
                                Erro exibir rastreabilidade
                            </TypographyP>
                        </TopCenterPannel>
                    )}
                    {isPending && (
                        <TopCenterPannel>
                            <Loader
                                className="duration-1000 animate-spin text-primary"
                                size={14}
                            />
                            <TypographyP>Carregando dados</TypographyP>
                        </TopCenterPannel>
                    )}
                    {isFetching && (
                        <TopCenterPannel>
                            <Loader
                                className="duration-1000 animate-spin text-amber-500"
                                size={14}
                            />
                            <TypographyP>Atualizando dados</TypographyP>
                        </TopCenterPannel>
                    )}
                    {!isFetching &&
                    !isPending &&
                    !isError &&
                    layoutedNodes.length === 0 ? (
                        <TopCenterPannel>
                            <TriangleAlert
                                className="text-amber-500"
                                size={14}
                            />
                            <TypographyP>
                                Não há rastreabilidade disponível
                            </TypographyP>
                        </TopCenterPannel>
                    ) : null}
                </AnimatePresence>
                {isFrom && (
                    <Panel
                        position="top-left"
                        className="px-2 py-1 bg-white border shadow hover:bg-neutral-100"
                    >
                        <div>
                            <Link
                                to={ROUTES.PRODUTO_ACABADO.getRoutePath()}
                                className="flex items-center gap-2 text-sm text-black/90"
                            >
                                <MoveLeft size={18} />
                                Produto acabado
                            </Link>
                        </div>
                    </Panel>
                )}
                <Controls />
                <Background />
                {/* <MiniMap /> */}
            </ReactFlow>
        </div>
    )
}

export default Flow
