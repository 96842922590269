import {
    fetchMonitorRecravacao,
    fetchMonitorRecravacaoFiltros,
} from '@/api/business/recravacao'
import {
    FetchMonitorRecravacaoFiltrosProps,
    FetchMonitorRecravacaoProps,
    MonitorRecravacao,
} from '@/api/business/recravacao/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const recravacaoKeys = {
    all: ['recravacao'] as const,
    lists: () => [...recravacaoKeys.all, 'list'] as const,
    list: (props: FetchMonitorRecravacaoProps) =>
        [...recravacaoKeys.lists(), { ...props }] as const,
    details: () => [...recravacaoKeys.all, 'detail'] as const,
    detail: (params: string) => [...recravacaoKeys.details(), params] as const,
    filters: ({ cod_filial }: FetchMonitorRecravacaoFiltrosProps) =>
        [...recravacaoKeys.lists(), 'filters', cod_filial] as const,
}

export type UseRecravacaoQueryProps = FetchMonitorRecravacaoProps & {
    enabled: boolean
}

export type UseRecravacaoFiltroQueryProps =
    FetchMonitorRecravacaoFiltrosProps & {
        enabled: boolean
    }

export const useMonitorRecravacaoFiltrosQuery = ({
    enabled,
    ...props
}: UseRecravacaoFiltroQueryProps) => {
    return useQuery({
        queryKey: recravacaoKeys.filters({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorRecravacaoFiltros({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) =>
            data.sort((a, b) =>
                a.centro_de_custo.localeCompare(b.centro_de_custo)
            ),
    })
}

export const useMonitorRecravacaoQuery = (props: UseRecravacaoQueryProps) => {
    return useQuery({
        queryKey: recravacaoKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorRecravacao({ ...props }),
        placeholderData: keepPreviousData,
        select: (data): MonitorRecravacao[] =>
            data.map((value) => ({
                ...value,
                turnos: value.turnos
                    .sort((a, b) => a.turno.localeCompare(b.turno))
                    .map((turno) => {
                        return {
                            ...turno,
                            resultados: turno.abreviatura_bem.map((bem) => {
                                return {
                                    ...bem,
                                    abreviatura_rolete:
                                        bem.abreviatura_rolete.sort((a, b) =>
                                            a.abreviatura_rolete.localeCompare(
                                                b.abreviatura_rolete
                                            )
                                        ),
                                }
                            }),
                        }
                    }),
            })),
    })
}
