import {
    useMonitorRecravacaoFiltrosQuery,
    useMonitorRecravacaoQuery,
} from '@/queries/useMonitorRecravacaoQuery'
import { format } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { TypographyMutedXs, TypographyXS } from '@/components/ui/typography'
import { DatePicker } from '@/components/DatePicker'
import CompanySelect from '../../components/CompanySelect'
import { CalendarX2, Maximize, Minimize, RefreshCcw } from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
// import { isBetween } from '@/utils/date'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import LinhasSelect from './components/LinhasSelect'
import RecravacaoTable from './components/RecravacaoTable'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorRecravacaoSlice.state.selectedCompany,
    date: state.monitorRecravacaoSlice.state.date,
    selectedSala: state.monitorRecravacaoSlice.state.selectedSala,
    setSala: state.monitorRecravacaoSlice.actions.setSala,
    setCompany: state.monitorRecravacaoSlice.actions.setCompany,
    setDateInterval: state.monitorRecravacaoSlice.actions.setDateInterval,
    resetDate: state.monitorRecravacaoSlice.actions.resetDate,
})

const MonitorRecravacao = () => {
    const [isFullScreen, setIsFullScreen] = useState(false)
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial },
        selectedSala,
        setDateInterval,
        setSala,
        setCompany,
        resetDate,
    } = useBaseStore(stateSelector)

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const { data: filters, isSuccess: isFiltersSuccess } =
        useMonitorRecravacaoFiltrosQuery({
            cod_filial: selectedCompany,
            enabled: empresas.length > 0 && isEmpresasSuccess,
        })

    const { data, isPending, isFetching, isError, dataUpdatedAt, refetch } =
        useMonitorRecravacaoQuery({
            cod_filial: selectedCompany,
            data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
            data_final: Number(format(new Date(initial), 'yyyyMMdd')),
            cod_bem: selectedSala.bem || 0,
            cod_centro_de_custo: selectedSala.sala || 0,
            enabled: isFiltersSuccess && filters.length > 0,
        })

    const onFullScreenClick = () => {
        document.fullscreenElement
            ? document.exitFullscreen()
            : mainContainerRef.current?.requestFullscreen()
    }

    useEffect(() => {
        const onFullScreenChange = () =>
            setIsFullScreen(!!document.fullscreenElement)

        if (mainContainerRef.current) {
            mainContainerRef.current.addEventListener(
                'fullscreenchange',
                onFullScreenChange
            )
        }

        return () => {
            mainContainerRef.current?.removeEventListener(
                'fullscreenchange',
                onFullScreenChange
            )
        }
    }, [])

    return (
        <PageContainer
            ref={mainContainerRef}
            className="pb-4 overflow-auto pt-14"
        >
            <div className="absolute top-4 right-4">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex justify-between px-4 pb-2">
                <div className="flex items-center gap-2">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany}
                        onChange={setCompany}
                    />
                    <LinhasSelect
                        data={filters}
                        onChange={(value) => {
                            const found = filters?.find(
                                (filter) =>
                                    `${filter.cod_centro_de_custo}${filter.cod_bem}` ===
                                    value
                            )
                            if (found)
                                setSala({
                                    bem: found.cod_bem,
                                    sala: found.cod_centro_de_custo,
                                })
                        }}
                        selectedId={
                            selectedSala.bem && selectedSala.sala
                                ? `${selectedSala.sala}${selectedSala.bem}`
                                : ''
                        }
                    />
                </div>
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-4">
                        <DatePicker
                            date={new Date(initial)}
                            setDate={(date) => {
                                if (date) {
                                    setDateInterval({
                                        type: 'initial',
                                        value: date.toISOString(),
                                    })
                                }
                            }}
                        />
                        {/* <DatePicker
                            date={new Date(final)}
                            setDate={(date) => {
                                if (date) {
                                    setDateInterval({
                                        type: 'final',
                                        value: date.toISOString(),
                                    })
                                }
                            }}
                            disabled={(date) => {
                                return (
                                    date < new Date(initial) ||
                                    !isBetween(
                                        new Date(date),
                                        new Date(initial),
                                        60
                                    )
                                )
                            }}
                        /> */}
                    </div>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => resetDate()}
                        tooltipContent={
                            <TypographyXS>Resetar data</TypographyXS>
                        }
                    >
                        <CalendarX2 size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={
                            <TypographyXS>Atualizar dados</TypographyXS>
                        }
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onFullScreenClick}
                        tooltipContent={
                            <TypographyXS>
                                {isFullScreen ? 'Minimizar' : 'Maximizar'}
                            </TypographyXS>
                        }
                    >
                        {isFullScreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            {/* <div className="flex-1 px-4 py-2 overflow-hidden"> */}
            <AnimatePresence>
                {!data && isPending ? (
                    <TopMessage variant="loading" text="Carregando dados..." />
                ) : null}
                {!isPending && isFetching ? (
                    <TopMessage variant="success" text="Atualizando dados..." />
                ) : null}
                {isError && (
                    <TopMessage variant="error" text="Erro ao buscar dados" />
                )}
            </AnimatePresence>
            <div className="p-4">
                {data?.map((value) => {
                    return value.turnos.map((turno) => (
                        <RecravacaoTable key={turno.turno} data={turno} />
                    ))
                })}
            </div>
        </PageContainer>
    )
}

export default MonitorRecravacao
