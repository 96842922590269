import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorMaturacao } from '@/api/business/maturacao/type'
import CellPopover from './components/CellPopover'

type MaturacaoTableProps = {
    data?: MonitorMaturacao[]
    lote: string
}

const MaturacaoTable = ({ data = [], lote }: MaturacaoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const filteredData = data.filter(
        (value) => value.lote_de_controle_de_qualidade === lote
    )

    const tableData = useMemo(() => {
        const uniqueValues = [
            ...new Set(
                filteredData.flatMap((value) =>
                    value.resultados.map((result) => result.ensaio)
                )
            ),
        ]

        const newData = uniqueValues.map((ensaio) => {
            const row = {
                ensaio,
                data: filteredData.filter((value) =>
                    value.resultados.some((result) => result.ensaio === ensaio)
                ),
            }

            return row
        })

        return newData
    }, [filteredData])

    const tableColumnHeaders = useMemo(() => {
        return [...new Set(filteredData.map((value) => value.produto))]
    }, [filteredData])

    type MaturacaoTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<MaturacaoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
            }),
            ...tableColumnHeaders.map((header) => {
                return columnHelper.display({
                    id: header,
                    header,
                    cell: ({ row }) => {
                        const { ensaio, data } = row.original

                        const value = data.find(
                            (value) => value.produto === header
                        )

                        if (!value) return null

                        return value.resultados
                            .filter((value) => value.ensaio === ensaio)
                            .map((value) => <CellPopover data={value} />)
                    },
                    size: 400,
                    meta: {
                        header: {
                            className: 'p-2',
                        },
                        cell: {
                            className: 'p-0',
                        },
                    },
                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<MaturacaoTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            rowStimateSize={40}
        />
    )
}

export default MaturacaoTable
