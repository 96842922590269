import { DebouncedInput } from '@/components/DebouncedInput'
import { Column, Table } from '@tanstack/react-table'
import { useState } from 'react'
import Datalist from './components/Datalist'
import { X } from 'lucide-react'
import { cn } from '@/lib/utils'

export const Filter = ({
    column,
    className,
}: {
    column: Column<any, unknown>
    table: Table<any>
    className?: string
}) => {
    const [isOpen, setOpen] = useState(false)
    const columnFilterValue = column.getFilterValue()

    return (
        <>
            {isOpen && <Datalist column={column} />}
            <div className="flex items-center gap-2">
                <DebouncedInput
                    type="text"
                    value={(columnFilterValue ?? '') as string}
                    onChange={(value) => column.setFilterValue(value)}
                    onFocus={() => setOpen(true)}
                    onBlur={() => setOpen(false)}
                    placeholder={`Buscar...`}
                    list={isOpen ? column.id + 'list' : undefined}
                    className={cn(className, 'flex-1')}
                />
                <button
                    onClick={() => column.setFilterValue('')}
                    className="flex items-center justify-center w-4 h-4"
                >
                    <X size={12} className="text-foreground" />
                </button>
            </div>
            <div className="h-1" />
        </>
    )
}
