import { Menu } from 'lucide-react'
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from '../ui/sheet'
import Icon from '@/assets/icons/icon.png'
import { TypographyH3, TypographyMutedXs, TypographyP } from '../ui/typography'
import { ROUTES } from '@/consts/routes'
import { useMemo } from 'react'
import useDisclosure from '@/hooks/useDisclosure'
import SidenavItem from './components/SidenavItem'

const Sidenav = () => {
    const { onOpenChange, isOpen } = useDisclosure()

    const routes = useMemo(
        () =>
            Object.values(ROUTES)
                .filter((route) => route.shouldShowOnSidenav)
                .sort((a, b) => a.label.localeCompare(b.label)),
        []
    )

    const rootRoutes = routes.filter((route) => !route.getParentRoute())

    return (
        <Sheet onOpenChange={onOpenChange} open={isOpen}>
            <SheetTrigger>
                <Menu size={14} className="text-foreground" />
            </SheetTrigger>
            <SheetContent side="left">
                <SheetHeader className="flex flex-row items-center gap-4 px-3.5 space-y-0">
                    <img src={Icon} alt="Logo" className="w-6 h-6" />
                    <TypographyH3>Monitor de Qualidade</TypographyH3>
                </SheetHeader>
                <div className="flex flex-col h-full py-4">
                    <div className="flex-1 space-y-2">
                        {rootRoutes.map((route) => {
                            const { label, index } = route

                            const children = routes.filter((child) => {
                                return child.getParentRoute() === index
                            })

                            return (
                                <div key={index}>
                                    <div className="px-4 py-1.5">
                                        <TypographyP className="font-semibold">
                                            {label}
                                        </TypographyP>
                                    </div>
                                    <div>
                                        {children.length > 0 ? (
                                            children.map((child) => {
                                                return (
                                                    <SidenavItem
                                                        key={child.index}
                                                        {...child}
                                                        onOpenChange={
                                                            onOpenChange
                                                        }
                                                    />
                                                )
                                            })
                                        ) : (
                                            <SidenavItem
                                                {...route}
                                                onOpenChange={onOpenChange}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="p-2">
                        <TypographyMutedXs className="block text-center">
                            V{import.meta.env.VITE_VERSION}
                        </TypographyMutedXs>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    )
}

export default Sidenav
