import {
    BaseEdge,
    EdgeLabelRenderer,
    EdgeProps,
    getBezierPath,
} from 'reactflow'
import { EdgeData } from '../..'
import {
    MonitorRastreabilidade,
    RastreabilidadeAninhada,
} from '@/api/business/rastreabilidade/type'
import { useEffect, useState } from 'react'
import { useTheme } from '@/context/ThemeContextProvider'

export type CustomEdgeProps = EdgeProps<EdgeData>

const edgeColors = {
    'PURO MALTE': { light: '#5c8fd6', dark: '#1e5bb0' },
    PILSEN: { light: undefined, dark: undefined },
    DUNKEL: { light: '#b45139', dark: '#b45139' },
    HELLES: { light: '#F49816', dark: '#F49816' },
    GOLD: { light: '#caac89', dark: '#a1815d' },
    LAGER: { light: '#28a654', dark: '#28a654' },
    CHOPP: { light: '#caac89', dark: '#a1815d' },
    ULTRA: { light: '#6f8ddb', dark: '#385ebc' },
    MACEDONIA: { light: '#419966', dark: '#39875a' },
}

export const getStrokeColor = (produto: string) => {
    const found = Object.keys(edgeColors).find((key) => produto.includes(key))

    return found
        ? edgeColors[found][document.documentElement.className]
        : undefined
}

const CustomEdge = ({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    markerEnd,
    style,
    label,
    data,
}: CustomEdgeProps) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })

    const { theme } = useTheme()

    const [edgeStyle, setEdgeStyle] = useState({
        ...style,
        strokeWidth: 2,
    })

    useEffect(() => {
        if (!(data as MonitorRastreabilidade).referencia) {
            const { produto } = data as RastreabilidadeAninhada

            setEdgeStyle((prev) => ({
                ...prev,
                stroke: getStrokeColor(produto),
            }))
        }
    }, [theme])

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={edgeStyle} />
            {label && (
                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            fontSize: 12,
                        }}
                        className="px-1 py-2 nodrag nopan bg-background"
                    >
                        {label}
                    </div>
                </EdgeLabelRenderer>
            )}
        </>
    )
}

export default CustomEdge
