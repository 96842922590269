import {
    fetchMonitorLubrificanteDeEsteira,
    fetchMonitorLubrificanteDeEsteiraFiltros,
} from '@/api/business/lubrificanteDeEsteira'
import {
    FetchMonitorLubrificanteDeEsteiraFiltrosProps,
    FetchMonitorLubrificanteDeEsteiraProps,
} from '@/api/business/lubrificanteDeEsteira/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const lubrificanteDeEsteiraKeys = {
    all: ['lubrificanteDeEsteira'] as const,
    lists: () => [...lubrificanteDeEsteiraKeys.all, 'list'] as const,
    list: (props: FetchMonitorLubrificanteDeEsteiraProps) =>
        [...lubrificanteDeEsteiraKeys.lists(), { ...props }] as const,
    details: () => [...lubrificanteDeEsteiraKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...lubrificanteDeEsteiraKeys.details(), params] as const,
    filters: ({ cod_filial }: FetchMonitorLubrificanteDeEsteiraFiltrosProps) =>
        [...lubrificanteDeEsteiraKeys.lists(), 'filters', cod_filial] as const,
}

export type UseLubrificanteDeEsteiraQueryProps =
    FetchMonitorLubrificanteDeEsteiraProps & {
        enabled: boolean
    }

export type UseLubrificanteDeEsteiraFiltroQueryProps =
    FetchMonitorLubrificanteDeEsteiraFiltrosProps & {
        enabled: boolean
    }

export const useMonitorLubrificanteDeEsteiraFiltrosQuery = ({
    enabled,
    ...props
}: UseLubrificanteDeEsteiraFiltroQueryProps) => {
    return useQuery({
        queryKey: lubrificanteDeEsteiraKeys.filters({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorLubrificanteDeEsteiraFiltros({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) =>
            data.sort((a, b) =>
                a.centro_de_custo.localeCompare(b.centro_de_custo)
            ),
    })
}

export const useMonitorLubrificanteDeEsteiraQuery = (
    props: UseLubrificanteDeEsteiraQueryProps
) => {
    return useQuery({
        queryKey: lubrificanteDeEsteiraKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorLubrificanteDeEsteira({ ...props }),
        placeholderData: keepPreviousData,
    })
}
