import { useMonitorRastreabilidadeQuery } from '@/queries/useMonitorRastreabilidadeQuery'
import { useEffect, useMemo, useRef } from 'react'
import PageContainer from '@/components/PageContainer'
// import { Store } from '@/store/type'
import Flow, { RastreabilidadeNode } from './components/Flow'
import {
    MonitorRastreabilidade as MonitorRastreabilidadeType,
    RastreabilidadeAninhada,
} from '@/api/business/rastreabilidade/type'
import { Edge } from 'reactflow'
import FlowMenu from './components/FlowMenu'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useLocation } from 'react-router-dom'

export type GetNodeAndEdgesFn = (
    data: MonitorRastreabilidadeType[],
    config?: { x: number; y: number }
) => { nodes: RastreabilidadeNode[]; edges: Edge[] }

const isMonitorRastreabilidade = (
    object: MonitorRastreabilidadeType | RastreabilidadeAninhada
): object is MonitorRastreabilidadeType => {
    return 'amostra_inicial_referencia' in object
}

const getNodesAndEdges: GetNodeAndEdgesFn = (
    data = [],
    config = { x: 0, y: 0 }
) => {
    const nodes: RastreabilidadeNode[] = []
    const edges: Edge[] = []

    const generateNode = (
        item: MonitorRastreabilidadeType | RastreabilidadeAninhada,
        parent?: string
    ) => {
        let id: string
        let width: number
        let isRoot = !parent

        if (isMonitorRastreabilidade(item)) {
            id = item.amostra_inicial_referencia.toString()
            // label = item.referencia
            width = Math.max(item.rastreabilidade.cenario.length) * 14
            getEdgesAndNodesRastreabilidade(item.rastreabilidade, id)
        } else {
            id = item.cod_lote_de_controle_de_qualidade.toString()
            // label = item.abreviatura_produto
            width =
                Math.max(item.cenario.length, item.abreviatura_produto.length) *
                11.5

            if (item.rastreabilidade) {
                getEdgesAndNodesRastreabilidade(item.rastreabilidade, id)
            }
        }

        nodes.push({
            id: id,
            position: { x: config.x, y: config.y },
            data: item,
            width: width,
            type: 'defaultNode',
            connectable: false,
        })

        if (parent && !isRoot) {
            edges.push({
                id: `${parent}-${id}`,
                source: parent,
                target: id,
                animated: true,
                deletable: false,
                data: item,
                type: 'defaultEdge',
            })
        }
    }

    const getEdgesAndNodesRastreabilidade = (
        rastreabilidade: RastreabilidadeAninhada | RastreabilidadeAninhada[],
        parent: string
    ) => {
        if (!rastreabilidade) return

        if (Array.isArray(rastreabilidade)) {
            rastreabilidade.forEach((item) => generateNode(item, parent))
        } else {
            generateNode(rastreabilidade, parent)
        }
    }

    data.forEach((item) => generateNode(item))

    return { nodes, edges }
}

const stateSelector = (state: Store) => ({
    setInitialState: state.monitorRastreabilidadeSlice.actions.setInitialState,
})

const MonitorRastreabilidade = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)

    const { setInitialState } = useBaseStore(stateSelector)

    const { data, isPending, isFetching, isError, isLoading } =
        useMonitorRastreabilidadeQuery({
            cod_filial: searchParams.get('empresa') || '',
            horaminuto: searchParams.get('horaminuto') || '',
            linha: searchParams.get('linha') || '',
            lotevalidade: searchParams.get('lote') || '',
        })

    const { edges = [], nodes = [] } = useMemo(
        () => (data ? getNodesAndEdges(data) : { edges: [], nodes: [] }),
        [data]
    )

    useEffect(() => {
        setInitialState({
            empresa: searchParams.get('empresa') || '',
            horaminuto: searchParams.get('horaminuto') || '',
            linha: searchParams.get('linha') || '',
            lote: searchParams.get('lote') || '',
        })
    }, [])

    return (
        <>
            <PageContainer
                ref={mainContainerRef}
                className="flex-row items-center"
            >
                <Flow
                    initialEdges={edges}
                    initialNodes={nodes}
                    isPending={isLoading}
                    isError={isError}
                    isFetching={isFetching && !isPending}
                />
                <FlowMenu />
            </PageContainer>
        </>
    )
}

export default MonitorRastreabilidade
