import { Handle, NodeProps, Position } from 'reactflow'
import { NodeData } from '../..'
import {
    MonitorRastreabilidade,
    RastreabilidadeAninhada,
} from '@/api/business/rastreabilidade/type'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { HTMLAttributes, ReactNode, memo } from 'react'

type CustomNodeProps = NodeProps<NodeData>

type NodeWrapperProps = HTMLAttributes<HTMLDivElement>

const NODE_CLASSNAME: Record<RastreabilidadeAninhada['origem'], string> = {
    ENVASAMENTO: 'bg-[#e9cda3] dark:text-[#333333] text-[#333333]',
    FILTRACAO: 'bg-[#ddb476] dark:text-[#444444] text-[#444444]',
    MATURACAO: 'bg-[#c7821a] dark:text-[#fffff0] text-[#fffff0]',
    FERMENTACAO: 'bg-[#9f6815] dark:text-[#dddddd] text-[#f4f4f4]',
    BRASSAGEM: 'bg-[#774e10] dark:text-[#eeeeee] text-[#f4f4f4]',
}

const NodeWrapper = ({ children, className, ...props }: NodeWrapperProps) => {
    return (
        <div
            className={cn(
                'p-4 border rounded-md shadow text-card-foreground bg-card ',
                className
            )}
            {...props}
        >
            {children}
        </div>
    )
}

const CustomNode = ({ data, id, isConnectable }: CustomNodeProps) => {
    if ((data as MonitorRastreabilidade).referencia) {
        const { referencia } = data as MonitorRastreabilidade

        return (
            <NodeWrapper className="dark:border-neutral-600">
                <div>
                    <TypographyP className="text-xs">{referencia}</TypographyP>
                </div>
                <Handle type="source" position={Position.Right} id={id} />
            </NodeWrapper>
        )
    } else {
        const {
            lote_de_controle_de_qualidade,
            cenario,
            rastreabilidade,
            origem,
            abreviatura_produto,
        } = data as RastreabilidadeAninhada

        let title: ReactNode = cenario

        if (origem === 'BRASSAGEM') {
            const first = cenario.slice(0, 3)
            const middle = cenario.slice(3, 7)
            const last = cenario.slice(-2)

            title = (
                <span className="overflow-hidden whitespace-nowrap text-ellipsis">
                    <span>{first}</span>
                    <span className="font-bold text-primary">{middle}</span>
                    <span>{last}</span>
                </span>
            )
        }

        return (
            <NodeWrapper className={NODE_CLASSNAME[origem]}>
                <Handle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                />
                <div className="space-y-1">
                    <TypographyP
                        className={cn(
                            'text-xs dark:text-foreground text-white font-bold',
                            NODE_CLASSNAME[origem]
                        )}
                    >
                        {title}
                    </TypographyP>
                    <TypographyMuted
                        className={cn(
                            'text-xs dark:text-foreground',
                            NODE_CLASSNAME[origem]
                        )}
                    >
                        {lote_de_controle_de_qualidade}
                    </TypographyMuted>
                    <TypographyMuted
                        className={cn(
                            'text-xs dark:text-foreground',
                            NODE_CLASSNAME[origem]
                        )}
                    >
                        {abreviatura_produto}
                    </TypographyMuted>
                </div>
                {rastreabilidade && (
                    <Handle type="source" position={Position.Right} id={id} />
                )}
            </NodeWrapper>
        )
    }
}

export default memo(CustomNode)
