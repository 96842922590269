import { fetchMonitorFiltracao } from '@/api/business/filtracao'
import { FetchMonitorFiltracaoProps } from '@/api/business/filtracao/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { isBefore } from 'date-fns'

export const filtracaoKeys = {
    all: ['filtracao'] as const,
    lists: () => [...filtracaoKeys.all, 'list'] as const,
    list: ({
        cod_filial,
        data_final,
        data_inicial,
    }: FetchMonitorFiltracaoProps) =>
        [
            ...filtracaoKeys.lists(),
            cod_filial,
            data_final,
            data_inicial,
        ] as const,
    details: () => [...filtracaoKeys.all, 'detail'] as const,
    detail: (params: string) => [...filtracaoKeys.details(), params] as const,
}

export type UseFiltracaoQueryProps = FetchMonitorFiltracaoProps & {
    enabled: boolean
}

export const useMonitorFiltracaoQuery = (props: UseFiltracaoQueryProps) => {
    return useQuery({
        queryKey: filtracaoKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorFiltracao({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) =>
            data
                .filter((value) => value.resultados.length > 0)
                .sort((a, b) => {
                    const nameComparison = a.cenario.localeCompare(b.cenario)

                    if (nameComparison !== 0) {
                        return nameComparison
                    }

                    const dateA = new Date(a.data)
                    const dateB = new Date(b.data)

                    return isBefore(dateA, dateB) ? 1 : -1
                }),
    })
}
