import instance from '..'
import {
    FetchMonitorPasteurizacaoFiltrosProps,
    FetchMonitorPasteurizacaoProps,
    MonitorPasteurizacao,
    MonitorPasteurizacaoFiltros,
} from './type'

export const fetchMonitorPasteurizacao = async (
    props: FetchMonitorPasteurizacaoProps
) => {
    const response = await instance.post<MonitorPasteurizacao[]>(
        '/monitor/envase/pasteurizacao',
        {
            ...props,
        }
    )

    return response.data
}

export const fetchMonitorPasteurizacaoFiltros = async (
    props: FetchMonitorPasteurizacaoFiltrosProps
) => {
    const response = await instance.post<MonitorPasteurizacaoFiltros[]>(
        '/monitor/recursos/pasteurizacao',
        {
            ...props,
        }
    )

    return response.data
}
