import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useMutation } from '@tanstack/react-query'
import { changePassword } from '@/api/auth/user'
import { useToast } from '../ui/use-toast'
import Button from '@/components/Button'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../ui/form'
import { Input } from '../ui/input'
import { useBaseStore } from '@/store'
import {
    appConfigActionsSelector,
    appConfigStateSelector,
} from '@/store/appConfigSlice'

const ChangePasswordDialog = () => {
    const { isChangePasswordDialogOpen: isOpen } = useBaseStore(
        appConfigStateSelector
    )
    const { onChangePasswordDialogClose: onClose } = useBaseStore(
        appConfigActionsSelector
    )

    const schema = z
        .object({
            password: z
                .string()
                .min(4, { message: 'Senha deve ter no mínimo 4 caracteres.' }),
            confirmPassword: z
                .string()
                .min(4, { message: 'Senha deve ter no mínimo 4 caracteres.' }),
        })
        .refine(
            ({ confirmPassword, password }) => confirmPassword === password,
            {
                message: 'Senhas não coincidem.',
                path: ['confirmPassword'],
            }
        )

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            password: '',
            confirmPassword: '',
        },
    })

    const { toast } = useToast()

    const { mutate, isPending } = useMutation({
        mutationFn: changePassword,
        onSuccess: () => {
            toast({
                title: 'Senha alterada com sucesso.',
                description: `Nova senha alterada com sucesso.`,
            })
            onClose()
        },
        onError: () => {
            toast({
                title: 'Erro ao alterar senha.',
                description:
                    'Houve um erro ao alterar a senha. Tente novamente.',
            })
        },
    })

    const onSubmit = ({ password }: z.infer<typeof schema>) => {
        mutate(password)
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[500px]">
                <DialogHeader>
                    <DialogTitle>Alterar Senha</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-2"
                    >
                        <FormField
                            name="password"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Nova senha</FormLabel>
                                    <FormControl>
                                        <Input {...field} type="password" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="confirmPassword"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Confirmar senha</FormLabel>
                                    <FormControl>
                                        <Input {...field} type="password" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button
                                className="px-3 rounded-md"
                                isLoading={isPending}
                            >
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default ChangePasswordDialog
