import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyMuted } from '@/components/ui/typography'

type ProdutosSelectProps = {
    produtos: { produto: string }[]
    value: string
    onChange: (value: string) => void
}

const ProdutosSelect = ({ produtos, value, onChange }: ProdutosSelectProps) => {
    return (
        <div className="space-y-1.5">
            <TypographyMuted>Produto</TypographyMuted>
            <Select onValueChange={onChange} value={value}>
                <SelectTrigger className="gap-4 p-4 text-sm w-[300px] hover:bg-accent">
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    {produtos.map((produto) => {
                        return (
                            <SelectItem
                                key={produto.produto}
                                value={produto.produto}
                            >
                                {produto.produto}
                            </SelectItem>
                        )
                    })}
                </SelectContent>
            </Select>
        </div>
    )
}

export default ProdutosSelect
