// import ExpiredToken from '@/components/ExpiredToken'
import Header from '@/components/Header'
import ChangePasswordDialog from '@/components/ChangePasswordDialog'
// import { useBaseStore } from '@/store'
import { Outlet } from 'react-router-dom'
import { ReactNode } from 'react'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
// import { Store } from '@/store/type'
import AsidePortalContainer from '@/components/AsidePortalContainer'
import { AsideContextProvider } from '@/context/AsideContext'
import { ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'
interface MainLayoutProps {
    children?: ReactNode
}

// const stateSelector = (state: Store) => ({
//     isSidenavOpen: state.appConfigSlice.state.isSidenavOpen,
//     tokenExpired: state.authSlice.state.tokenExpired,
// })

const MainLayout = ({ children }: MainLayoutProps) => {
    // const { tokenExpired } = useBaseStore(stateSelector)

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    )

    return (
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToHorizontalAxis]}
            sensors={sensors}
        >
            <AsideContextProvider>
                <div className="h-screen overflow-hidden">
                    <Header />
                    <div className="w-full overflow-hidden h-container-header">
                        {/* {tokenExpired && <ExpiredToken />} */}
                        <ResizablePanelGroup
                            direction="horizontal"
                            className="h-full bg-background"
                        >
                            <ResizablePanel>
                                {children || <Outlet />}
                            </ResizablePanel>
                            <AsidePortalContainer />
                        </ResizablePanelGroup>
                    </div>
                </div>
            </AsideContextProvider>
            <ChangePasswordDialog />
        </DndContext>
    )
}

export default MainLayout
