import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import { TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { Bell } from 'lucide-react'

type EditedValuePopoverProps = {
    value: number | string
    isReanalisis?: boolean
}

const EditedValuePopover = ({
    value,
    isReanalisis,
}: EditedValuePopoverProps) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger className="flex items-center gap-1">
                    <Bell size={14} className="text-red-600" />
                    <span className={cn(isReanalisis && 'text-amber-600')}>
                        {value}
                    </span>
                </TooltipTrigger>
                <TooltipContent>
                    <TypographyP>Esse valor foi corrigido.</TypographyP>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}

export default EditedValuePopover
