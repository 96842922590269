import instance from '..'
import {
    FetchMonitorProvaHorariaFiltrosProps,
    FetchMonitorProvaHorariaProps,
    MonitorProvaHoraria,
    MonitorProvaHorariaFiltros,
} from './type'

export const fetchMonitorProvaHoraria = async (
    props: FetchMonitorProvaHorariaProps
) => {
    const response = await instance.post<MonitorProvaHoraria[]>(
        'monitor/provahoraria',
        {
            ...props,
        }
    )

    return response.data
}

export const fetchMonitorProvaHorariaFiltros = async (
    props: FetchMonitorProvaHorariaFiltrosProps
) => {
    const response = await instance.post<MonitorProvaHorariaFiltros[]>(
        '/monitor/recursos/provahoraria',
        {
            ...props,
        }
    )

    return response.data
}
