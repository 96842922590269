import { DebouncedInput } from '@/components/DebouncedInput'
import { TypographyMuted } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { TriangleAlert } from 'lucide-react'

type FlowMenuInputProps = {
    onBlur: (value: string) => void
    label: string
    value: string
}

const FlowMenuInput = ({ label, value, onBlur }: FlowMenuInputProps) => {
    // const [inputValue, setInputValue] = useState(value)

    // useEffect(() => {
    //     setInputValue(value)
    // }, [value])

    return (
        <div className="space-y-1 group">
            <div className="flex items-center gap-1.5 px-3">
                {!value && (
                    <TriangleAlert size={14} className="text-amber-600" />
                )}
                <TypographyMuted>{label}</TypographyMuted>
            </div>
            <DebouncedInput
                className={cn(
                    'bg-transparent border-transparent rounded-sm shadow-none focus-visible:ring-sky-700 group-hover:border-neutral-400 dark:group-hover:border-neutral-600',
                    !value && '[&:not(:focus-visible)]:border-destructive'
                )}
                value={value}
                onChange={(value) => onBlur(value.toString())}
                debounce={700}
            />
        </div>
    )
}

export default FlowMenuInput
