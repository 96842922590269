import instance from '..'
import {
    FetchMonitorMaturacaoFiltrosProps,
    FetchMonitorMaturacaoProps,
    MonitorMaturacao,
    MonitorMaturacaoFiltros,
} from './type'

export const fetchMonitorMaturacao = async (
    props: FetchMonitorMaturacaoProps
) => {
    const response = await instance.post<MonitorMaturacao[]>(
        '/monitor/maturacao',
        {
            ...props,
        }
    )

    return response.data
}

export const fetchMonitorMaturacaoFiltros = async (
    props: FetchMonitorMaturacaoFiltrosProps
) => {
    const response = await instance.post<MonitorMaturacaoFiltros[]>(
        '/monitor/recursos/maturacao',
        {
            ...props,
        }
    )

    return response.data
}
