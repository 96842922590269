import { fetchMonitorBrassagem } from '@/api/business/brassagem'
import { FetchMonitorBrassagemProps } from '@/api/business/brassagem/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const monitorBrassagemKeys = {
    all: ['monitorBrassagem'] as const,
    lists: () => [...monitorBrassagemKeys.all, 'list'] as const,
    list: ({
        cod_filial,
        data_final,
        data_inicial,
        sala_de_brassagem,
    }: FetchMonitorBrassagemProps) =>
        [
            ...monitorBrassagemKeys.lists(),
            cod_filial,
            data_final,
            data_inicial,
            sala_de_brassagem,
        ] as const,
    details: () => [...monitorBrassagemKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...monitorBrassagemKeys.details(), params] as const,
}

export type UseMonitorBrassagemQueryProps = FetchMonitorBrassagemProps & {
    enabled: boolean
}

export const useMonitorBrassagemQuery = ({
    enabled,
    ...props
}: UseMonitorBrassagemQueryProps) => {
    return useQuery({
        queryKey: monitorBrassagemKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorBrassagem({ ...props }),
        placeholderData: keepPreviousData,
    })
}
