import Icon from '@/assets/icons/icon.png'

const LoadingScreen = () => {
    return (
        <div className="flex items-center justify-center w-screen h-full overflow-hidden">
            <img
                src={Icon}
                alt="Truck"
                className="w-20 h-20 duration-1000 animate-ping"
            />
        </div>
    )
}

export default LoadingScreen
