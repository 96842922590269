import {
    useMonitorFermentacaoFiltrosQuery,
    useMonitorFermentacaoQuery,
} from '@/queries/useMonitorFermentacaoQuery'
import { format, isBefore } from 'date-fns'
import { useMemo, useRef, useState } from 'react'
import { TypographyMutedXs, TypographyXS } from '@/components/ui/typography'
import { DatePicker } from '@/components/DatePicker'
import CompanySelect from '../../components/CompanySelect'
import {
    CalendarX2,
    Maximize,
    Minimize,
    RefreshCcw,
    Search,
} from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import FermentacaoTable from './components/FermentacaoTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import LinhasSelect from './components/LinhasSelect'
import { AnimatePresence } from 'framer-motion'
import LotesSelect from './components/LotesSelect'
import AcompanhamentoTable from './components/AcompanhamentoTable'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { Button } from '@/components/ui/button'
import { useFullscreen } from '@/hooks/useFullscreen'
import { isBetween } from '@/utils/date'
import { toast } from 'sonner'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorFermentacaoSlice.state.selectedCompany,
    date: state.monitorFermentacaoSlice.state.date,
    selectedSala: state.monitorFermentacaoSlice.state.selectedSala,
    selectedLote: state.monitorFermentacaoSlice.state.selectedLote,
    setLote: state.monitorFermentacaoSlice.actions.setLote,
    setSala: state.monitorFermentacaoSlice.actions.setSala,
    setCompany: state.monitorFermentacaoSlice.actions.setCompany,
    setDateInterval: state.monitorFermentacaoSlice.actions.setDateInterval,
    resetDate: state.monitorFermentacaoSlice.actions.resetDate,
})

const MonitorFermentacao = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial, final },
        selectedSala,
        selectedLote,
        setLote,
        setDateInterval,
        setSala,
        setCompany,
    } = useBaseStore(stateSelector)

    const [{ from, to }, setDate] = useState({
        from: new Date(initial) || new Date(),
        to: new Date(final) || new Date(),
    })

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const { data: filters } = useMonitorFermentacaoFiltrosQuery({
        cod_filial: selectedCompany,
        enabled: isEmpresasSuccess && empresas.length > 0,
    })

    const { data, isPending, isFetching, isError, dataUpdatedAt, refetch } =
        useMonitorFermentacaoQuery({
            cod_filial: selectedCompany,
            data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
            data_final: Number(format(new Date(final), 'yyyyMMdd')),
            fermentador: [selectedSala.bem!],
            enabled: !!selectedSala.bem,
        })

    const lotes = data
        ? [...new Set(data.map((value) => value.lote_de_controle_de_qualidade))]
              .map((lote) => {
                  const found = data.find(
                      (value) => value.lote_de_controle_de_qualidade === lote
                  )
                  return found!
              })
              .filter(Boolean)
        : []

    const tableData = useMemo(
        () =>
            data
                ? data.filter(
                      (value) =>
                          value.lote_de_controle_de_qualidade === selectedLote
                  )
                : [],
        [selectedLote]
    )

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onChangeDate = (date: Date, type: 'from' | 'to') => {
        setDate((prev) => ({
            ...prev,
            [type]: date,
        }))
    }

    const onResetDate = () => {
        setDate({
            from: new Date(),
            to: new Date(),
        })
    }

    const onSearch = () => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    return (
        <PageContainer
            ref={mainContainerRef}
            className="pb-4 overflow-auto pt-14"
        >
            <div className="absolute top-4 right-4">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex justify-between px-4 pb-2">
                <div className="flex items-center gap-2">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany.toString()}
                        onChange={(value) => setCompany(Number(value))}
                    />
                    <LinhasSelect
                        data={filters}
                        onChange={(value) => {
                            const found = filters?.find(
                                (filter) => filter.cod_bem.toString() === value
                            )
                            if (found)
                                setSala({
                                    bem: found.cod_bem,
                                })
                        }}
                        selectedId={
                            selectedSala.bem ? selectedSala.bem.toString() : ''
                        }
                    />
                    <LotesSelect
                        onChange={(value) => setLote(value)}
                        data={lotes}
                        selectedId={selectedLote}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-4">
                        <Button variant="ghost" size="icon" onClick={onSearch}>
                            <Search size={16} />
                        </Button>
                        <DatePicker
                            date={from}
                            setDate={(date) => {
                                if (date) {
                                    onChangeDate(date, 'from')
                                }
                            }}
                        />
                        <DatePicker
                            date={to}
                            setDate={(date) => {
                                if (date) {
                                    onChangeDate(date, 'to')
                                }
                            }}
                        />
                    </div>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => onResetDate()}
                        tooltipContent={
                            <TypographyXS>Resetar data</TypographyXS>
                        }
                    >
                        <CalendarX2 size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={
                            <TypographyXS>Atualizar dados</TypographyXS>
                        }
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            <TypographyXS>
                                {isFullscreen ? 'Minimizar' : 'Maximizar'}
                            </TypographyXS>
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            <div className="flex-1 px-4 py-2 overflow-hidden">
                <AnimatePresence>
                    {!data && isPending ? (
                        <TopMessage
                            variant="loading"
                            text="Carregando dados..."
                        />
                    ) : null}
                    {!isPending && isFetching ? (
                        <TopMessage
                            variant="success"
                            text="Atualizando dados..."
                        />
                    ) : null}
                    {isError && (
                        <TopMessage
                            variant="error"
                            text="Erro ao buscar dados"
                        />
                    )}
                </AnimatePresence>
                <div className="flex gap-6">
                    <div className="flex-1 overflow-hidden">
                        <FermentacaoTable
                            data={tableData}
                            lote={selectedLote}
                        />
                    </div>
                    <AcompanhamentoTable data={tableData} lote={selectedLote} />
                </div>
            </div>
        </PageContainer>
    )
}

export default MonitorFermentacao
