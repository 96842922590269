import { useBaseStore } from '@/store'
import { getInitials } from '@/utils/stringFormatter'
import { Avatar, AvatarFallback } from '../ui/avatar'
import ProfileMenu from './components/ProfileMenu'
import { TypographyP } from '../ui/typography'
import { Separator } from '../ui/separator'
import { Store } from '@/store/type'
import Icon from '@/assets/icons/icon.png'
import Sidenav from '../Sidenav'
import { ROUTES } from '@/consts/routes'
import { useLocation } from 'react-router-dom'
import { findRouteByIndex } from '@/hooks/useRouteNavigator'
import { useMemo } from 'react'

const stateSelector = (state: Store) => ({
    user: state.authSlice.state.user,
})

const Header = () => {
    const { user } = useBaseStore(stateSelector)
    const { pathname } = useLocation()

    const routes = useMemo(() => Object.values(ROUTES), [])
    const route = findRouteByIndex(routes, pathname)

    return (
        <>
            <div className="flex items-center justify-between w-full px-4 h-header bg-background">
                <div className="flex items-center gap-4">
                    <Sidenav />
                    <div className="flex items-center gap-2">
                        <img src={Icon} alt="Logo" className="w-6 h-6" />
                        <TypographyP className="font-bold">
                            Monitor de Qualidade
                        </TypographyP>
                    </div>
                    <TypographyP className="px-2 py-1 text-xs font-semibold rounded-md dark:bg-accent bg-accent/50 text-primary">
                        {route?.label}
                    </TypographyP>
                </div>
                <div className="flex items-center h-full gap-4 ml-auto">
                    <ProfileMenu
                        className="p-4 hover:bg-accent"
                        trigger={
                            <div className="flex items-center h-full gap-2 pointer-events-none">
                                <Avatar className="w-6 h-6 text-xs">
                                    <AvatarFallback className="bg-primary-500 text-gray-50">
                                        {getInitials(user || '')}
                                    </AvatarFallback>
                                </Avatar>
                                <TypographyP className="hidden pointer-events-none sm:block">
                                    {user}
                                </TypographyP>
                            </div>
                        }
                    />
                </div>
            </div>
            <Separator />
        </>
    )
}

export default Header
