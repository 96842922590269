import { EMPRESAS } from '@/consts/companies'
import { ImmerStateCreator } from '../type'
import { MonitorRecravacaoSlice, MonitorRecravacaoState } from './type'

const initialState: MonitorRecravacaoState = {
    selectedCompany: EMPRESAS[0].value,
    selectedSala: { bem: undefined, sala: undefined },
    date: {
        initial: new Date().toISOString(),
        // final: new Date().toISOString(),
    },
}

const persist = (state: MonitorRecravacaoState) => {
    localStorage.setItem('monitor-recravacao', JSON.stringify(state))
}

const getInicialState = () => {
    try {
        const storage = localStorage.getItem('monitor-recravacao')

        if (storage) {
            return JSON.parse(storage)
        }

        return initialState
    } catch (error) {
        console.log(error)
        return initialState
    }
}

export const useMonitorRecravacaoSlice: ImmerStateCreator<
    MonitorRecravacaoSlice
> = (set) => ({
    state: getInicialState(),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorRecravacaoSlice.state.selectedCompany = company

                persist(state.monitorRecravacaoSlice.state)
            })
        },
        setDateInterval: ({ value, type }) => {
            set((state) => {
                state.monitorRecravacaoSlice.state.date[type] = value

                persist(state.monitorRecravacaoSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorRecravacaoSlice.state.selectedSala = sala

                persist(state.monitorRecravacaoSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorRecravacaoSlice.state.date = {
                    // final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorRecravacaoSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorRecravacaoSlice.state = initialState
            })
        },
    },
})
