import instance from '..'
import { FetchMonitorFiltracaoProps, MonitorFiltracao } from './type'

const BASE_URL = '/monitor/filtracao'

export const fetchMonitorFiltracao = async (
    props: FetchMonitorFiltracaoProps
) => {
    const response = await instance.post<MonitorFiltracao[]>(BASE_URL, {
        ...props,
    })

    return response.data
}
