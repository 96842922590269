import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorFermentacao } from '@/api/business/fermentacao/type'
import CellPopover from './components/CellPopover'

type FermentacaoTableProps = {
    data?: MonitorFermentacao[]
    lote: string
}

const FermentacaoTable = ({ data = [] }: FermentacaoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const filteredData = data.map((value) => ({
        ...value,
        resultados: value.resultados.filter(
            (result) => result.skip_lote !== 'ACOMPANHAMENTO DA FERMENTAÇÃO'
        ),
    }))

    const tableData = useMemo(() => {
        const headers = [
            ...new Set(
                filteredData.flatMap((value) =>
                    value.resultados.map((result) => result.ensaio)
                )
            ),
        ]

        const newData = headers.map((ensaio) => {
            const row = {
                ensaio,
                data: filteredData.filter((value) =>
                    value.resultados.some((result) => result.ensaio === ensaio)
                ),
            }

            return row
        })

        return newData
    }, [filteredData])

    const tableColumnHeaders = useMemo(() => {
        return [
            ...new Set(
                filteredData.flatMap((value) =>
                    value.resultados.map((result) => result.skip_lote)
                )
            ),
        ]
    }, [filteredData])

    type FermentacaoTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<FermentacaoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
            }),
            ...tableColumnHeaders.map((header) => {
                return columnHelper.display({
                    id: header,
                    header: () => {
                        switch (header) {
                            case 'A CADA COLETA DE GRAU FINAL':
                                return 'GRAU FINAL'
                            case 'A CADA FINAL DE FERMENTAÇÃO':
                                return 'FINAL DE FERMENTAÇÃO'
                            case 'A CADA PRIMEIRA COLETA DA FERMENTAÇÃO':
                                return 'PRIMEIRA COLETA'
                            default:
                                return header
                        }
                    },
                    cell: ({ row }) => {
                        const { ensaio, data } = row.original

                        const results = data.flatMap((value) =>
                            value.resultados.filter(
                                (result) => result.skip_lote === header
                            )
                        )

                        return results
                            .filter((value) => value.ensaio === ensaio)
                            .map((value) => <CellPopover data={value} />)
                    },
                    size: 200,
                    meta: {
                        header: {
                            className: 'p-2',
                        },
                        cell: {
                            className: 'p-0',
                        },
                    },
                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<FermentacaoTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            rowStimateSize={40}
            meta={{
                layout: 'stretch',
            }}
        />
    )
}

export default FermentacaoTable
