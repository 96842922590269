import { fetchMonitorRastreabilidade } from '@/api/business/rastreabilidade'
import { FetchMonitorRastreabilidadeProps } from '@/api/business/rastreabilidade/type'
import { useQuery } from '@tanstack/react-query'

export const rastreabilidadeKeys = {
    all: ['rastreabilidade'] as const,
    lists: () => [...rastreabilidadeKeys.all, 'list'] as const,
    list: ({
        cod_filial,
        horaminuto,
        linha,
        lotevalidade,
    }: FetchMonitorRastreabilidadeProps) =>
        [
            ...rastreabilidadeKeys.lists(),
            cod_filial,
            horaminuto,
            linha,
            lotevalidade,
        ] as const,
}

export type UseRastreabilidadeQueryProps = FetchMonitorRastreabilidadeProps

export const useMonitorRastreabilidadeQuery = ({
    ...props
}: UseRastreabilidadeQueryProps) => {
    const enabled =
        !!props.cod_filial &&
        !!props.horaminuto &&
        !!props.linha &&
        !!props.lotevalidade

    return useQuery({
        queryFn: () => fetchMonitorRastreabilidade({ ...props }),
        queryKey: rastreabilidadeKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
    })
}
