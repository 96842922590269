import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { Turno } from '@/api/business/arrolhamento/type'
import CellPopover from './components/CellPopover'

type ArrolhamentoTableProps = {
    data: Turno
}

const ArrolhamentoTable = ({ data }: ArrolhamentoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const tableData = useMemo(() => {
        const uniqueValues = [
            ...new Set(
                data.abreviatura_bem.flatMap((value) =>
                    value.abreviatura_arrolhador.flatMap((rolete) =>
                        rolete.cod_amostra.flatMap((amostra) =>
                            amostra.resultados_amostra.map(
                                (resultado) => resultado.ensaio
                            )
                        )
                    )
                )
            ),
        ]

        const newData = uniqueValues.map((ensaio) => {
            const row = {
                ensaio,
                data: data.abreviatura_bem.filter((bem) =>
                    bem.abreviatura_arrolhador.filter((rolete) =>
                        rolete.cod_amostra.some((rol) =>
                            rol.resultados_amostra.some(
                                (resultado) => resultado.ensaio === ensaio
                            )
                        )
                    )
                ),
            }

            return row
        })

        return newData
    }, [data])

    const tableColumnHeaders = useMemo(() => {
        return [
            ...new Set(
                data.abreviatura_bem.flatMap((bem) =>
                    bem.abreviatura_arrolhador.map(
                        (rolete) => rolete.abreviatura_arrolhador
                    )
                )
            ),
        ]
    }, [data])

    type ArrolhamentoTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<ArrolhamentoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 220,
                enableHiding: false,
                meta: {
                    cell: { className: 'p-3' },
                },
            }),
            columnHelper.group({
                id: 'data',
                header: `Turno ${data.turno}`,
                cell: () => 'a',
                columns: tableColumnHeaders.map((header) => {
                    return columnHelper.display({
                        id: `data.${header}`,
                        header: header,
                        size: 100,
                        enableHiding: false,
                        meta: {
                            cell: {
                                className: 'p-0',
                            },
                        },
                        cell: ({ row }) => {
                            const { data, ensaio } = row.original
                            console.log(data)
                            const found = data
                                .find((value) =>
                                    value.abreviatura_arrolhador.some(
                                        (rolete) =>
                                            rolete.abreviatura_arrolhador ===
                                            header
                                    )
                                )
                                ?.abreviatura_arrolhador.filter(
                                    (rolete) =>
                                        rolete.abreviatura_arrolhador === header
                                )
                                .flatMap((rolete) =>
                                    rolete.cod_amostra.flatMap((amostra) =>
                                        amostra.resultados_amostra.filter(
                                            (resultado) =>
                                                resultado.ensaio === ensaio
                                        )
                                    )
                                )
                            console.log(found)
                            if (!found) return null
                            const resultados = found.filter(
                                (value) => value.ensaio === ensaio
                            )
                            console.log(resultados)
                            return resultados.map((result) => (
                                <CellPopover data={result} />
                            ))
                        },
                    })
                }),
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<ArrolhamentoTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            className="h-auto overflow-visible"
            meta={{ layout: 'stretch' }}
        />
    )
}

export default ArrolhamentoTable
