import instance from '..'
import {
    FetchMonitorLubrificanteDeEsteiraFiltrosProps,
    FetchMonitorLubrificanteDeEsteiraProps,
    MonitorLubrificanteDeEsteira,
    MonitorLubrificanteDeEsteiraFiltros,
} from './type'

export const fetchMonitorLubrificanteDeEsteira = async (
    props: FetchMonitorLubrificanteDeEsteiraProps
) => {
    const response = await instance.post<MonitorLubrificanteDeEsteira[]>(
        '/monitor/envase/lubrificantedeesteira',
        {
            ...props,
        }
    )

    return response.data
}

export const fetchMonitorLubrificanteDeEsteiraFiltros = async (
    props: FetchMonitorLubrificanteDeEsteiraFiltrosProps
) => {
    const response = await instance.post<MonitorLubrificanteDeEsteiraFiltros[]>(
        '/monitor/recursos/lubrificantedeesteira',
        {
            ...props,
        }
    )

    return response.data
}
