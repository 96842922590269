import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { MonitorFiltracao } from '@/api/business/filtracao/type'
import CellPopover from './components/CellPopover'
import { TypographyP, TypographyXS } from '@/components/ui/typography'
import { format, isBefore } from 'date-fns'
import { getDate, getDateFromId } from '@/utils/date'

type FiltracaoTableProps = {
    data?: MonitorFiltracao[]
}

const FiltracaoTable = ({ data = [] }: FiltracaoTableProps) => {
    const tableData = useMemo(() => {
        const uniqueValues = [
            ...new Set(
                data.flatMap((value) =>
                    value.resultados.map((result) => result.ensaio)
                )
            ),
        ]

        const newData = uniqueValues.map((ensaio) => {
            const row = {
                ensaio,
                data: data.filter((value) =>
                    value.resultados.some((result) => result.ensaio === ensaio)
                ),
            }

            return row
        })

        return newData
    }, [data])

    const tableColumnHeaders = useMemo(() => {
        return [
            ...new Set(
                data.map((value) => value.cod_lote_de_controle_de_qualidade)
            ),
        ].reduce((acc, curr) => {
            const found = data
                .flatMap((value) => value.resultados)
                .filter(
                    (result) =>
                        Number(result.cod_lote_de_controle_de_qualidade) ===
                        curr
                )

            return {
                ...acc,
                [curr]: [...new Set(found.map((value) => value.cod_amostra))]
                    .map((cod) => {
                        const found2 = found.find(
                            (val) => val.cod_amostra === cod
                        )

                        return {
                            cod_amostra: found2?.cod_amostra || '',
                            skip_lote: found2?.skip_lote.includes('PRÉVIA')
                                ? 'PRÉVIA'
                                : 'LIBERAÇÃO' || '',
                            data: found2?.data_da_coleta,
                            hora: found2?.hora_da_coleta,
                        }
                    })
                    .sort((a, b) => {
                        if (a.data && a.hora && b.data && b.hora) {
                            const dateA = getDate({
                                date: a.data,
                                hour: a.hora,
                            })
                            const dateB = getDate({
                                date: b.data,
                                hour: b.hora,
                            })

                            if (isBefore(dateA, dateB)) {
                                return -1
                            } else if (isBefore(dateB, dateA)) {
                                return 1
                            }
                        }

                        return a.skip_lote.localeCompare(b.skip_lote)
                    }),
            }
        }, {} as Record<string, { cod_amostra: string; skip_lote: string; data: string; hora: string }[]>)
    }, [data])

    type FiltracaoTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<FiltracaoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
            }),
            ...Object.keys(tableColumnHeaders).map((header) => {
                const headerValue = data.find(
                    (value) =>
                        value.cod_lote_de_controle_de_qualidade ===
                        Number(header)
                )

                return columnHelper.group({
                    id: header.toString(),
                    header: () => {
                        return (
                            <div className="flex flex-col gap-2 overflow-hidden">
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {headerValue?.cenario}
                                </TypographyXS>
                            </div>
                        )
                    },
                    columns: tableColumnHeaders[header].map(
                        ({ cod_amostra, skip_lote, data, hora }, idx) => {
                            return columnHelper.display({
                                id: `${header}-${data}-${hora}-${idx}`,
                                header: () => {
                                    const label = skip_lote.includes('PRÉVIA')
                                        ? `${skip_lote} ${(idx + 1)
                                              .toString()
                                              .padStart(2, '0')}`
                                        : skip_lote

                                    return (
                                        <>
                                            <TypographyP className="text-xs">
                                                {label}
                                            </TypographyP>
                                            {headerValue && (
                                                <>
                                                    <TypographyP className="text-xs font-normal">
                                                        {format(
                                                            getDateFromId(
                                                                headerValue.data
                                                            ),
                                                            'dd/MM/yyyy'
                                                        )}
                                                    </TypographyP>
                                                    <TypographyP className="text-xs font-normal">
                                                        {hora}
                                                    </TypographyP>
                                                </>
                                            )}
                                        </>
                                    )
                                },
                                cell: ({ row }) => {
                                    const { data, ensaio } = row.original

                                    const found = data.find(
                                        (value) =>
                                            value.cod_lote_de_controle_de_qualidade ===
                                                Number(header) &&
                                            value.resultados.some(
                                                (result) =>
                                                    result.cod_amostra ===
                                                        cod_amostra &&
                                                    result.ensaio === ensaio
                                            )
                                    )

                                    if (!found) return null

                                    return found.resultados
                                        .filter(
                                            (result) =>
                                                result.cod_amostra ===
                                                    cod_amostra &&
                                                result.ensaio === ensaio
                                        )
                                        .map((result) => {
                                            return <CellPopover data={result} />
                                        })
                                },
                                // size: skip.includes('PRÉVIA') ? 310 : 120,
                                size: 120,
                                meta: {
                                    header: {
                                        className: 'p-2',
                                    },
                                    cell: {
                                        className: 'p-0',
                                    },
                                },
                                enableHiding: false,
                            })
                        }
                    ),
                    size: 310,
                    meta: {
                        header: {
                            className: 'p-2',
                        },
                        cell: {
                            className: 'p-0',
                        },
                    },
                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return <Table<FiltracaoTableData> data={tableData} columns={columns} />
}

export default FiltracaoTable
