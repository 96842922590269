import instance from '..'
import {
    FetchMonitorProdutosAcabadosProps,
    MonitorProdutosAcabados,
} from './type'

const BASE_URL = '/monitor/produtosacabados'

export const fetchMonitorProdutosAcabados = async (
    props: FetchMonitorProdutosAcabadosProps
) => {
    const response = await instance.post<MonitorProdutosAcabados[]>(BASE_URL, {
        ...props,
    })

    return response.data
}
