// import { EMPRESAS } from '@/consts/companies'
import { ImmerStateCreator } from '../type'
import {
    MonitorRastreabilidadeSlice,
    MonitorRastreabilidadeState,
} from './type'

const initialState: MonitorRastreabilidadeState = {
    empresa: '',
    horaminuto: '',
    linha: '',
    lote: '',
}

export const useMonitorRastreabilidadeSlice: ImmerStateCreator<
    MonitorRastreabilidadeSlice
> = (set) => ({
    state: initialState,
    actions: {
        setInitialState: (initial) => {
            set((state) => {
                state.monitorRastreabilidadeSlice.state = initial
            })
        },
        setState: (key, value) => {
            set((state) => {
                state.monitorRastreabilidadeSlice.state[key] = value
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorRastreabilidadeSlice.state = initialState
            })
        },
    },
})
