import { MonitorFermentacao } from '@/api/business/fermentacao/type'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { getDate } from '@/utils/date'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import CellPopover from '../FermentacaoTable/components/CellPopover'
import { format, isValid } from 'date-fns'
import { TypographyLarge } from '@/components/ui/typography'

type AcompanhamentoTableProps = {
    data?: MonitorFermentacao[]
    lote: string
}

type FermentacaoTableData = TableData<{ date: string }>

const columnHelper = createColumnHelper<FermentacaoTableData>()

const AcompanhamentoTable = ({ data = [] }: AcompanhamentoTableProps) => {
    const filteredData = data.map((value) => ({
        ...value,
        resultados: value.resultados.filter(
            (result) => result.skip_lote === 'ACOMPANHAMENTO DA FERMENTAÇÃO'
        ),
    }))

    const results = filteredData.flatMap((value) => value.resultados)

    const tableColumnHeaders = useMemo(() => {
        const headers = [...new Set(results.map((result) => result.ensaio))]

        return headers
    }, [data])

    const tableData = useMemo(() => {
        const uniqueValues = [
            ...new Set(
                results.flatMap((value) => {
                    const date = getDate({
                        date: value.data_da_coleta,
                        hour: value.hora_da_coleta,
                    }).toISOString()

                    return date
                })
            ),
        ].map((date) => {
            const found = results.filter((result) => {
                const resDate = getDate({
                    date: result.data_da_coleta,
                    hour: result.hora_da_coleta,
                }).toISOString()

                return resDate === date
            })

            const newObject = {
                date,
            }

            if (found) {
                tableColumnHeaders.forEach((ensaio) => {
                    const ensaioFound = found.find(
                        (value) => value.ensaio === ensaio
                    )

                    newObject[ensaio] = ensaioFound
                })
            }

            return newObject
        })

        return uniqueValues
    }, [data])

    const columns = useMemo(
        () => [
            columnHelper.accessor(
                ({ date }) => {
                    const isDateValid = isValid(new Date(date))
                        ? new Date(date)
                        : null

                    if (!isDateValid) return null

                    return format(isDateValid, 'dd/MM/yyyy, HH:mm:ss')
                },
                {
                    id: 'date',
                    header: 'Data',
                    size: 200,
                    enableHiding: false,
                }
            ),
            ...tableColumnHeaders.map((header) =>
                columnHelper.display({
                    id: header,
                    header,
                    cell: ({ row }) => {
                        const { date } = row.original
                        const value = tableData.find(
                            (value) => value.date === date && value[header]
                        )

                        if (!value) return null

                        return <CellPopover data={value[header]} />
                    },
                })
            ),
        ],
        [tableColumnHeaders]
    )

    return (
        <div className="w-1/3 px-4 space-y-4">
            <div>
                <TypographyLarge>Acompanhamento</TypographyLarge>
            </div>
            <Table<FermentacaoTableData>
                data={tableData}
                columns={columns}
                meta={{
                    layout: 'stretch',
                }}
                rowStimateSize={40}
            />
        </div>
    )
}

export default AcompanhamentoTable
