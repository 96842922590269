import { MonitorProvaHorariaFiltros } from '@/api/business/provaHoraria/type'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyMutedXs, TypographyXS } from '@/components/ui/typography'

type LinhasSelectProps = {
    data?: MonitorProvaHorariaFiltros[]
    selectedId: string
    onChange: (value: string) => void
}

const LinhasSelect = ({ data, selectedId, onChange }: LinhasSelectProps) => {
    return (
        <Select value={selectedId} onValueChange={onChange}>
            <SelectTrigger className="hover:bg-muted h-[56px] min-w-[200px]">
                <SelectValue
                    className="text-start"
                    placeholder="Selecione uma linha"
                />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {data?.map((value) => {
                        return (
                            <SelectItem
                                key={value.cod_ponto_de_coleta}
                                value={value.cod_ponto_de_coleta.toString()}
                            >
                                <div className="flex flex-col p-1 space-y-1">
                                    <TypographyXS className="text-start">
                                        {value.centro_de_custo}
                                    </TypographyXS>
                                    <TypographyMutedXs className="text-start">
                                        {value.bem}
                                    </TypographyMutedXs>
                                </div>
                            </SelectItem>
                        )
                    })}
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}

export default LinhasSelect
