import { fetchMonitorProdutosAcabados } from '@/api/business/produtosAcabados'
import {
    FetchMonitorProdutosAcabadosProps,
    MonitorProdutosAcabadosSelect,
} from '@/api/business/produtosAcabados/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const produtosAcabadosKeys = {
    all: ['produtosAcabados'] as const,
    lists: () => [...produtosAcabadosKeys.all, 'list'] as const,
    list: ({
        cod_filial,
        data_final,
        data_inicial,
    }: FetchMonitorProdutosAcabadosProps) =>
        [
            ...produtosAcabadosKeys.lists(),
            cod_filial,
            data_final,
            data_inicial,
        ] as const,
    details: () => [...produtosAcabadosKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...produtosAcabadosKeys.details(), params] as const,
}

export type UseProdutosAcabadosQueryProps =
    FetchMonitorProdutosAcabadosProps & {
        enabled: boolean
    }

export const useMonitorProdutosAcabadosQuery = ({
    enabled,
    ...props
}: UseProdutosAcabadosQueryProps) => {
    return useQuery({
        queryKey: produtosAcabadosKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorProdutosAcabados({ ...props }),
        placeholderData: keepPreviousData,
        select: (data): MonitorProdutosAcabadosSelect[] =>
            data
                .filter((value) => value.resultados.length > 0)
                .reverse()
                .map((value) => {
                    const firstResult = value.resultados[0]

                    if (!firstResult) return { ...value, horaminuto: '0' }

                    const { hora_da_coleta } = firstResult

                    const hourArray = hora_da_coleta.split(':')
                    const hour = `${hourArray[0]}${hourArray[1]}`

                    return {
                        ...value,
                        horaminuto: hour,
                    }
                }),
    })
}
