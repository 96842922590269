import { cn } from '@/lib/utils'
import { VariantProps, cva } from 'class-variance-authority'
import { motion } from 'framer-motion'

type TopMessageProps = {
    text: string
    className?: string
} & VariantProps<typeof variants>

const variants = cva('mb-4 w-full rounded-md py-1 text-center text-xs', {
    variants: {
        variant: {
            success:
                'bg-secondary-50 text-secondary-500 dark:bg-accent dark:text-primary',
            loading:
                'bg-primary-50 text-primary-600 dark:bg-accent dark:text-primary',
            error: 'text-desctructive-foreground bg-destructive',
        },
    },
    defaultVariants: { variant: 'success' },
})

const TopMessage = ({ text, className, variant }: TopMessageProps) => {
    return (
        <motion.div
            className={cn(variants({ variant, className }))}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
        >
            {text}
        </motion.div>
    )
}

export default TopMessage
