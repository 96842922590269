import {
    useMonitorLubrificanteDeEsteiraFiltrosQuery,
    useMonitorLubrificanteDeEsteiraQuery,
} from '@/queries/useMonitorLubrificanteDeEsteiraQuery'
import { format, isBefore } from 'date-fns'
import { useMemo, useRef, useState } from 'react'
import { TypographyMutedXs, TypographyXS } from '@/components/ui/typography'
import { DatePicker } from '@/components/DatePicker'
import CompanySelect from '../../components/CompanySelect'
import {
    CalendarX2,
    Maximize,
    Minimize,
    RefreshCcw,
    Search,
} from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
// import { isBetween } from '@/utils/date'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import LinhasSelect from './components/LinhasSelect'
import LubrificanteDeEsteiraTable from './components/LubrificanteDeEsteiraTable'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { useFullscreen } from '@/hooks/useFullscreen'
import { Button } from '@/components/ui/button'
import { toast } from 'sonner'
import { isBetween } from '@/utils/date'

const stateSelector = (state: Store) => ({
    selectedCompany:
        state.monitorLubrificanteDeEsteiraSlice.state.selectedCompany,
    date: state.monitorLubrificanteDeEsteiraSlice.state.date,
    selectedSala: state.monitorLubrificanteDeEsteiraSlice.state.selectedSala,
    setSala: state.monitorLubrificanteDeEsteiraSlice.actions.setSala,
    setCompany: state.monitorLubrificanteDeEsteiraSlice.actions.setCompany,
    setDateInterval:
        state.monitorLubrificanteDeEsteiraSlice.actions.setDateInterval,
    resetDate: state.monitorLubrificanteDeEsteiraSlice.actions.resetDate,
})

const MonitorLubrificanteDeEsteira = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial, final },
        selectedSala,
        setDateInterval,
        setSala,
        setCompany,
    } = useBaseStore(stateSelector)

    const [{ from, to }, setDate] = useState({
        from: new Date(initial) || new Date(),
        to: new Date(final) || new Date(),
    })

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const { data: filters, isSuccess: isFiltersSuccess } =
        useMonitorLubrificanteDeEsteiraFiltrosQuery({
            cod_filial: selectedCompany,
            enabled: empresas.length > 0 && isEmpresasSuccess,
        })

    const { data, isPending, isFetching, isError, dataUpdatedAt, refetch } =
        useMonitorLubrificanteDeEsteiraQuery({
            cod_filial: selectedCompany,
            data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
            data_final: Number(format(new Date(final), 'yyyyMMdd')),
            cod_centro_de_custo: selectedSala.sala || 0,
            enabled: isFiltersSuccess && filters.length > 0,
        })

    const turnos = useMemo(
        () =>
            [...new Set(data?.map((value) => value.turno))].sort((a, b) =>
                a.localeCompare(b)
            ),
        [data]
    )

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onChangeDate = (date: Date, type: 'from' | 'to') => {
        setDate((prev) => ({
            ...prev,
            [type]: date,
        }))
    }

    const onResetDate = () => {
        setDate({
            from: new Date(),
            to: new Date(),
        })
    }

    const onSearch = () => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    return (
        <PageContainer
            ref={mainContainerRef}
            className="pb-4 overflow-auto pt-14"
        >
            <div className="absolute top-4 right-4">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex justify-between px-4 pb-2">
                <div className="flex items-center gap-2">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany}
                        onChange={setCompany}
                    />
                    <LinhasSelect
                        data={filters}
                        onChange={(value) => {
                            const found = filters?.find(
                                (filter) =>
                                    `${filter.cod_centro_de_custo}${filter.cod_bem}` ===
                                    value
                            )
                            if (found)
                                setSala({
                                    bem: found.cod_bem,
                                    sala: found.cod_centro_de_custo,
                                })
                        }}
                        selectedId={
                            selectedSala.bem && selectedSala.sala
                                ? `${selectedSala.sala}${selectedSala.bem}`
                                : ''
                        }
                    />
                </div>
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-4">
                        <Button variant="ghost" size="icon" onClick={onSearch}>
                            <Search size={16} />
                        </Button>
                        <DatePicker
                            date={from}
                            setDate={(date) => {
                                if (date) {
                                    onChangeDate(date, 'from')
                                }
                            }}
                        />
                        <DatePicker
                            date={to}
                            setDate={(date) => {
                                if (date) {
                                    onChangeDate(date, 'to')
                                }
                            }}
                        />
                    </div>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => onResetDate()}
                        tooltipContent={
                            <TypographyXS>Resetar data</TypographyXS>
                        }
                    >
                        <CalendarX2 size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={
                            <TypographyXS>Atualizar dados</TypographyXS>
                        }
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            <TypographyXS>
                                {isFullscreen ? 'Minimizar' : 'Maximizar'}
                            </TypographyXS>
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            {/* <div className="flex-1 px-4 py-2 overflow-hidden"> */}
            <AnimatePresence>
                {!data && isPending ? (
                    <TopMessage variant="loading" text="Carregando dados..." />
                ) : null}
                {!isPending && isFetching ? (
                    <TopMessage variant="success" text="Atualizando dados..." />
                ) : null}
                {isError && (
                    <TopMessage variant="error" text="Erro ao buscar dados" />
                )}
            </AnimatePresence>
            <div className="p-4 space-y-4">
                {turnos?.map((turno) => {
                    const lubrificantes = data?.filter(
                        (value) => value.turno === turno
                    )

                    return (
                        lubrificantes && (
                            <LubrificanteDeEsteiraTable
                                key={turno}
                                turno={turno}
                                data={lubrificantes}
                            />
                        )
                    )
                })}
            </div>
        </PageContainer>
    )
}

export default MonitorLubrificanteDeEsteira
