import {
    fetchMonitorPasteurizacao,
    fetchMonitorPasteurizacaoFiltros,
} from '@/api/business/pasteurizacao'
import {
    FetchMonitorPasteurizacaoFiltrosProps,
    FetchMonitorPasteurizacaoProps,
} from '@/api/business/pasteurizacao/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const pasteurizacaoKeys = {
    all: ['pasteurizacao'] as const,
    lists: () => [...pasteurizacaoKeys.all, 'list'] as const,
    list: (props: FetchMonitorPasteurizacaoProps) =>
        [...pasteurizacaoKeys.lists(), { ...props }] as const,
    details: () => [...pasteurizacaoKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...pasteurizacaoKeys.details(), params] as const,
    filters: ({ cod_filial }: FetchMonitorPasteurizacaoFiltrosProps) =>
        [...pasteurizacaoKeys.lists(), 'filters', cod_filial] as const,
}

export type UsePasteurizacaoQueryProps = FetchMonitorPasteurizacaoProps & {
    enabled: boolean
}

export type UsePasteurizacaoFiltroQueryProps =
    FetchMonitorPasteurizacaoFiltrosProps & {
        enabled: boolean
    }

export const useMonitorPasteurizacaoFiltrosQuery = (
    props: UsePasteurizacaoFiltroQueryProps
) => {
    return useQuery({
        queryKey: pasteurizacaoKeys.filters({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorPasteurizacaoFiltros({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) =>
            data.sort((a, b) =>
                a.centro_de_custo.localeCompare(b.centro_de_custo)
            ),
    })
}

export const useMonitorPasteurizacaoQuery = (
    props: UsePasteurizacaoQueryProps
) => {
    return useQuery({
        queryKey: pasteurizacaoKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorPasteurizacao({ ...props }),
        placeholderData: keepPreviousData,
    })
}
