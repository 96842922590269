import instance from '..'
import {
    FetchMonitorRecravacaoFiltrosProps,
    FetchMonitorRecravacaoProps,
    MonitorRecravacao,
    MonitorRecravacaoFiltros,
} from './type'

export const fetchMonitorRecravacao = async (
    props: FetchMonitorRecravacaoProps
) => {
    const response = await instance.post<MonitorRecravacao[]>(
        '/monitor/envase/recravacao',
        {
            ...props,
        }
    )

    return response.data
}

export const fetchMonitorRecravacaoFiltros = async (
    props: FetchMonitorRecravacaoFiltrosProps
) => {
    const response = await instance.post<MonitorRecravacaoFiltros[]>(
        '/monitor/recursos/analiserecravacao',
        {
            ...props,
        }
    )

    return response.data
}
