import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorProdutosAcabadosSelect } from '@/api/business/produtosAcabados/type'
import CellPopover from './components/CellPopover'
import { TypographyP, TypographyXS } from '@/components/ui/typography'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/consts/routes'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { Waypoints } from 'lucide-react'

type ProdutosAcabadosTableProps = {
    data?: MonitorProdutosAcabadosSelect[]
}

const ProdutosAcabadosTable = ({ data = [] }: ProdutosAcabadosTableProps) => {
    const navigate = useNavigate()

    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const tableData = useMemo(() => {
        const uniqueValues = [
            ...new Set(
                data.flatMap((value) =>
                    value.resultados
                        .sort((a, b) => b.cod_laboratorio - a.cod_laboratorio)
                        .map((result) => result.ensaio)
                )
            ),
        ]

        const newData = uniqueValues.map((ensaio) => {
            const row = {
                ensaio,
                data: data.filter((value) =>
                    value.resultados.some((result) => result.ensaio === ensaio)
                ),
            }

            return row
        })

        return newData
    }, [data])

    const tableColumnHeaders = useMemo(() => {
        return [
            ...new Set(
                data
                    .sort((a, b) => (a.horaminuto < b.horaminuto ? 1 : -1))
                    .map((value) => value.lote_de_controle_de_qualidade)
            ),
        ]
    }, [data])

    type ProdutosAcabadosTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<ProdutosAcabadosTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
            }),
            ...tableColumnHeaders.map((header) => {
                const headerValue = data.find(
                    (value) =>
                        value.lote_de_controle_de_qualidade ===
                        header.toString()
                )

                const lotes = [...new Set(headerValue?.lotes_envasamento)]

                return columnHelper.display({
                    id: header.toString(),
                    header: () => {
                        return (
                            <div className="flex flex-col gap-2 overflow-hidden">
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {headerValue?.produto}
                                </TypographyXS>
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {headerValue?.centro_de_custo}
                                </TypographyXS>
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {lotes}
                                </TypographyXS>
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {headerValue?.origem}
                                </TypographyXS>
                            </div>
                        )
                    },
                    cell: ({ row }) => {
                        const { ensaio } = row.original

                        const ensaioFound = tableData.find(
                            (value) => value.ensaio === ensaio
                        )

                        if (!ensaioFound) return null

                        const lote = ensaioFound.data.find(
                            (value) =>
                                value.lote_de_controle_de_qualidade === header
                        )

                        if (!lote) return null

                        const value = lote.resultados.find(
                            (value) => value.ensaio === ensaio
                        )

                        if (!value) return null

                        return <CellPopover data={value} lote={header} />
                    },
                    size: 300,
                    meta: {
                        header: {
                            className: 'p-2',
                            menu: {
                                renderMenuItem: () => {
                                    const linha =
                                        headerValue?.centro_de_custo
                                            .split('-')[0]
                                            .trim()
                                            .split('') || ''

                                    const abreviatura =
                                        linha[0] + linha[linha.length - 1]

                                    return (
                                        <DropdownMenuItem
                                            className="gap-2 focus:cursor-pointer group"
                                            onClick={() =>
                                                navigate(
                                                    ROUTES.RASTREABILIDADE
                                                        .index +
                                                        '/?lote=' +
                                                        `${lotes}` +
                                                        '&empresa=' +
                                                        `${headerValue?.cod_filial}` +
                                                        '&horaminuto=' +
                                                        `${headerValue?.horaminuto}` +
                                                        '&linha=' +
                                                        `${abreviatura}`,
                                                    {
                                                        state: {
                                                            from: ROUTES
                                                                .PRODUTO_ACABADO
                                                                .index,
                                                        },
                                                    }
                                                )
                                            }
                                        >
                                            <Waypoints
                                                size={14}
                                                className="text-muted-foreground group-hover:text-primary-600"
                                            />
                                            <TypographyP>
                                                Rastreabilidade
                                            </TypographyP>
                                        </DropdownMenuItem>
                                    )
                                },
                            },
                        },
                        cell: {
                            className: 'p-0',
                        },
                    },
                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<ProdutosAcabadosTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
        />
    )
}

export default ProdutosAcabadosTable
