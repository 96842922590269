import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useTheme } from '@/context/ThemeContextProvider'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useQueryClient } from '@tanstack/react-query'
import { Key, Laptop, LogOut, Moon, Sun } from 'lucide-react'
import { ReactNode } from 'react'

interface ProfileMenuProps {
    trigger: ReactNode
    className?: string
}

const stateSelector = (state: Store) => ({
    onChangePasswordDialogOpen:
        state.appConfigSlice.actions.onChangePasswordDialogOpen,
    logout: state.authSlice.actions.logout,
})

const ProfileMenu = ({ trigger, className }: ProfileMenuProps) => {
    const { onChangePasswordDialogOpen, logout } = useBaseStore(stateSelector)
    const { setTheme } = useTheme()

    const queryClient = useQueryClient()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger className={className}>
                {trigger}
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[220px]">
                <DropdownMenuGroup>
                    <DropdownMenuLabel>Conta</DropdownMenuLabel>
                    <DropdownMenuItem
                        className="gap-2"
                        onClick={onChangePasswordDialogOpen}
                    >
                        <Key size={14} />
                        Alterar senha
                    </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuLabel>Tema</DropdownMenuLabel>
                    <DropdownMenuItem
                        className="gap-2"
                        onClick={() => setTheme('light')}
                    >
                        <Sun size={14} />
                        Claro
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        className="gap-2"
                        onClick={() => setTheme('dark')}
                    >
                        <Moon size={14} />
                        Escuro
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        className="gap-2"
                        onClick={() => setTheme('system')}
                    >
                        <Laptop size={14} />
                        Sistema
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                </DropdownMenuGroup>

                <DropdownMenuItem
                    className="gap-2"
                    onClick={() => logout(queryClient)}
                >
                    <LogOut size={14} />
                    Sair
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export default ProfileMenu
