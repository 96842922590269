import {
    useMonitorProvaHorariaFiltrosQuery,
    useMonitorProvaHorariaQuery,
} from '@/queries/useMonitorProvaHorariaQuery'
import { format, isBefore } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { TypographyMutedXs, TypographyXS } from '@/components/ui/typography'
import { DatePicker } from '@/components/DatePicker'
import CompanySelect from '../../components/CompanySelect'
import {
    CalendarX2,
    Maximize,
    Minimize,
    RefreshCcw,
    Search,
} from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import ProvaHorariaTable from './components/ProvaHorariaTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import LinhasSelect from './components/LinhasSelect'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { useFullscreen } from '@/hooks/useFullscreen'
import { Button } from '@/components/ui/button'
import { toast } from 'sonner'
import { isBetween } from '@/utils/date'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorProvaHorariaSlice.state.selectedCompany,
    date: state.monitorProvaHorariaSlice.state.date,
    selectedSala: state.monitorProvaHorariaSlice.state.selectedSala,
    setSala: state.monitorProvaHorariaSlice.actions.setSala,
    setCompany: state.monitorProvaHorariaSlice.actions.setCompany,
    setDateInterval: state.monitorProvaHorariaSlice.actions.setDateInterval,
    resetDate: state.monitorProvaHorariaSlice.actions.resetDate,
})

const MonitorProvaHoraria = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial, final },
        selectedSala,
        setDateInterval,
        setSala,
        setCompany,
    } = useBaseStore(stateSelector)

    const [{ from, to }, setDate] = useState({
        from: new Date(initial) || new Date(),
        to: new Date(final) || new Date(),
    })

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const { data: filters, isSuccess: isFiltersSuccess } =
        useMonitorProvaHorariaFiltrosQuery({
            cod_filial: selectedCompany,
            enabled: empresas.length > 0 && isEmpresasSuccess,
        })

    const { data, isPending, isFetching, isError, dataUpdatedAt, refetch } =
        useMonitorProvaHorariaQuery({
            cod_filial: selectedCompany,
            data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
            data_final: Number(format(new Date(final), 'yyyyMMdd')),
            cod_ponto_de_coleta:
                filters?.find(
                    (filter) => filter.cod_ponto_de_coleta === selectedSala
                )?.conjunto_ponto_de_coleta || [],
            enabled: isFiltersSuccess && filters.length > 0,
        })

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onChangeDate = (date: Date, type: 'from' | 'to') => {
        setDate((prev) => ({
            ...prev,
            [type]: date,
        }))
    }

    const onResetDate = () => {
        setDate({
            from: new Date(),
            to: new Date(),
        })
    }

    const onSearch = () => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    useEffect(() => {
        if (filters) {
            setSala(filters[0].cod_ponto_de_coleta, true)
        }
    }, [filters])

    return (
        <PageContainer ref={mainContainerRef} className="pb-4 pt-14">
            <div className="absolute top-4 right-4">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex justify-between px-4 pb-2">
                <div className="flex items-center gap-2">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany}
                        onChange={setCompany}
                    />
                    <LinhasSelect
                        data={filters}
                        onChange={(value) => setSala(Number(value))}
                        selectedId={selectedSala?.toString() || ''}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-4">
                        <Button variant="ghost" size="icon" onClick={onSearch}>
                            <Search size={16} />
                        </Button>
                        <DatePicker
                            date={from}
                            setDate={(date) => {
                                if (date) {
                                    onChangeDate(date, 'from')
                                }
                            }}
                        />
                        <DatePicker
                            date={to}
                            setDate={(date) => {
                                if (date) {
                                    onChangeDate(date, 'to')
                                }
                            }}
                        />
                    </div>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => onResetDate()}
                        tooltipContent={
                            <TypographyXS>Resetar data</TypographyXS>
                        }
                    >
                        <CalendarX2 size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={
                            <TypographyXS>Atualizar dados</TypographyXS>
                        }
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            <TypographyXS>
                                {isFullscreen ? 'Minimizar' : 'Maximizar'}
                            </TypographyXS>
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            <div className="flex-1 px-4 py-2 overflow-hidden">
                <AnimatePresence>
                    {!data && isPending ? (
                        <TopMessage
                            variant="loading"
                            text="Carregando dados..."
                        />
                    ) : null}
                    {!isPending && isFetching ? (
                        <TopMessage
                            variant="success"
                            text="Atualizando dados..."
                        />
                    ) : null}
                    {isError && (
                        <TopMessage
                            variant="error"
                            text="Erro ao buscar dados"
                        />
                    )}
                </AnimatePresence>
                <ProvaHorariaTable data={data} />
            </div>
        </PageContainer>
    )
}

export default MonitorProvaHoraria
