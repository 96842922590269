import instance from '..'
import { FetchMonitorBrassagemProps, MonitorBrassagem } from './type'

const BASE_URL = '/monitor/brassagem'

export const fetchMonitorBrassagem = async (
    props: FetchMonitorBrassagemProps
) => {
    const response = await instance.post<MonitorBrassagem[]>(BASE_URL, {
        ...props,
    })

    return response.data
}
