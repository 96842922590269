import { differenceInDays, formatDistanceToNowStrict, parseISO } from 'date-fns'
import { format } from 'date-fns-tz'
import { ptBR } from 'date-fns/locale'
import {
    getDay,
    intlFormatDistance,
    isSameWeek,
    isToday,
    isYesterday,
} from 'date-fns'

type Months = {
    short: string
    long: string
    number: number
}

type Years = {
    short: string
    long: string
}

export const getLocaleDate = (date: string, dateFormat: string) => {
    const timezone = 'America/Sao_Paulo'

    const formattedDate = format(parseISO(date), dateFormat, {
        timeZone: timezone,
    })

    return formattedDate
}

export const getRemainingTime = (date: string) => {
    const dateObj = new Date(date)

    return formatDistanceToNowStrict(dateObj, {
        unit: 'day',
        locale: ptBR,
    })
}

const WEEKDAYS = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

export const getMonthsFromRange = (start: number = 0, end: number = 11) => {
    let startIdx = start > end ? end : start
    const endIdx = end < start ? start : end

    const months: Months[] = []

    while (startIdx <= endIdx) {
        const monthDate = new Date(new Date().getFullYear(), startIdx, 1)
        months.push({
            number: monthDate.getMonth(),
            short: monthDate
                .toLocaleString('default', { month: 'short' })
                .toUpperCase()
                .replace('.', ' '),
            long: monthDate
                .toLocaleString('default', { month: 'long' })
                .toUpperCase(),
        })
        startIdx++
    }
    return months
}

export const getYearsFromRange = (
    start: number = new Date().getFullYear() - 4,
    end: number = new Date().getFullYear() + 4
) => {
    let startIdx = start > end ? end : start
    const endIdx = end < start ? start : end

    const years: Years[] = []

    while (startIdx <= endIdx) {
        const yearDate = new Date(startIdx, 0, 1)

        years.push({
            short: yearDate.toLocaleString('pt-br', { year: '2-digit' }),
            long: yearDate.getFullYear().toString(),
        })
        startIdx++
    }

    return years
}

export const getLocaleStringDate = (date: Date) => {
    if (isToday(date)) {
        return intlFormatDistance(date, new Date(), {
            locale: ptBR as unknown as string,
        })
    } else if (isYesterday(date)) {
        return `Ontem ${format(date, 'HH:mm')}`
    } else if (isSameWeek(date, new Date())) {
        return `${WEEKDAYS[getDay(date)]} ${format(date, 'HH:mm')}`
    } else {
        return format(date, 'dd/MM HH:mm')
    }
}

export const getDateFromId = (id: number) => {
    const idString = id.toString()

    return new Date(
        Number(idString.slice(0, 4)),
        Number(idString.slice(4, 6)) - 1,
        Number(idString.slice(6, 8))
    )
}

export const getIdFromDate = (date: Date) => {
    const dateString = format(date, 'yyyyMMdd')

    return [
        dateString.slice(0, 4),
        dateString.slice(4, 6),
        dateString.slice(6, 8),
    ]
}

export const isBetween = (
    firstDate: string | Date,
    secondDate: string | Date,
    daysInterval: number
) => {
    const date1 = firstDate instanceof Date ? firstDate : parseISO(firstDate)
    const date2 = secondDate instanceof Date ? secondDate : parseISO(secondDate)

    const difference = Math.abs(differenceInDays(date1, date2))

    return difference <= daysInterval
}

export const getDate = ({ date, hour }: { date: string; hour: string }) => {
    const [ano, mes, dia] = date.split('-')
    const [hora, minuto, segundo] = hour.split(':')

    return new Date(
        Number(ano),
        Number(mes) - 1,
        Number(dia),
        Number(hora),
        Number(minuto),
        Number(segundo)
    )
}
