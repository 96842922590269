import {
    Beer,
    Clock4,
    CookingPot,
    Droplet,
    Factory,
    Filter,
    Lock,
    LogIn,
    LucideIcon,
    PocketKnife,
    ShieldBan,
    ThermometerSun,
    Waypoints,
} from 'lucide-react'
import { IconType } from 'react-icons/lib'
import { TbChartBubble } from 'react-icons/tb'
import { PiBeerBottle } from 'react-icons/pi'

export type RouteLabel =
    | 'Processo'
    | 'Brassagem'
    | 'Filtração'
    | 'Login'
    | 'Recuperar senha'
    | 'Produto acabado'
    | 'Envasamento'
    | 'Rastreabilidade'
    | 'Recravação'
    | 'Envase'
    | 'Pasteurizador'
    | 'Lubrificante de esteira'
    | 'Not Found'
    | 'Arrolhamento'
    | 'Maturação'
    | 'Fermentação'

export type Route = {
    index: string
    label: RouteLabel
    icon: LucideIcon | IconType | null
    shouldShowOnSidenav: boolean
    getParentRoute: () => string
    getRoutePath: () => string
}

export const ROUTES: Record<string, Route> = {
    HOME: {
        index: '/',
        label: 'Brassagem',
        icon: Factory,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () => ROUTES.PROCESSO.index,
    },
    FILTRACAO: {
        index: 'filtracao',
        label: 'Filtração',
        icon: Filter,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () =>
            ROUTES.FILTRACAO.getParentRoute() + '/' + ROUTES.FILTRACAO.index,
    },
    PRODUTO_ACABADO: {
        index: 'produto-acabado',
        label: 'Produto acabado',
        icon: Beer,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.PRODUTO_ACABADO.getParentRoute() +
            '/' +
            ROUTES.PRODUTO_ACABADO.index,
    },
    ENVASAMENTO: {
        index: 'envasamento',
        label: 'Envasamento',
        icon: PiBeerBottle,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.ENVASAMENTO.getParentRoute() +
            '/' +
            ROUTES.ENVASAMENTO.index,
    },
    RASTREABILIDADE: {
        index: '/rastreabilidade',
        label: 'Rastreabilidade',
        icon: Waypoints,
        shouldShowOnSidenav: true,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.RASTREABILIDADE.index,
    },
    RECRAVACAO: {
        index: 'recravacao',
        label: 'Recravação',
        icon: CookingPot,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.RECRAVACAO.getParentRoute() + '/' + ROUTES.RECRAVACAO.index,
    },
    PASTEURIZACAO: {
        index: 'pasteurizacao',
        label: 'Pasteurizador',
        icon: ThermometerSun,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.PASTEURIZACAO.getParentRoute() +
            '/' +
            ROUTES.PASTEURIZACAO.index,
    },
    ARROLHAMENTO: {
        index: 'arrolhamento',
        label: 'Arrolhamento',
        icon: PocketKnife,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.ARROLHAMENTO.getParentRoute() +
            '/' +
            ROUTES.ARROLHAMENTO.index,
    },
    LUBRIFICANTE_DE_ESTEIRA: {
        index: 'lubrificante-de-esteira',
        label: 'Lubrificante de esteira',
        icon: Droplet,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.LUBRIFICANTE_DE_ESTEIRA.getParentRoute() +
            '/' +
            ROUTES.LUBRIFICANTE_DE_ESTEIRA.index,
    },
    MATURACAO: {
        index: 'maturacao',
        label: 'Maturação',
        icon: Clock4,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () =>
            ROUTES.MATURACAO.getParentRoute() + '/' + ROUTES.MATURACAO.index,
    },
    FERMENTACAO: {
        index: 'fermentacao',
        label: 'Fermentação',
        icon: TbChartBubble,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () =>
            ROUTES.FERMENTACAO.getParentRoute() +
            '/' +
            ROUTES.FERMENTACAO.index,
    },
    ENVASE: {
        index: '/envase',
        label: 'Envase',
        icon: null,
        shouldShowOnSidenav: true,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.ENVASE.index,
    },
    PROCESSO: {
        index: '/processo',
        label: 'Processo',
        icon: null,
        shouldShowOnSidenav: true,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.PROCESSO.index,
    },
    LOGIN: {
        index: 'login',
        label: 'Login',
        icon: LogIn,
        shouldShowOnSidenav: false,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.LOGIN.index,
    },
    RECOVER_PASSWORD: {
        index: 'recuperar-senha',
        label: 'Recuperar senha',
        icon: Lock,
        shouldShowOnSidenav: false,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.RECOVER_PASSWORD.index,
    },
    NOT_FOUND: {
        index: '*',
        label: 'Not Found',
        icon: ShieldBan,
        shouldShowOnSidenav: false,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.NOT_FOUND.index,
    },
} as const
