import { Resultado } from '@/api/business/pasteurizacao/type'
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from '@/components/ui/hover-card'
import { Separator } from '@/components/ui/separator'
import {
    TypographyMutedXs,
    TypographyP,
    // TypographyXS,
} from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import EditedValuePopover from '@/pages/MonitorBrassagem/components/BrassagemTable/components/EditedValuePopover'
import { HoverCardPortal } from '@radix-ui/react-hover-card'
import { format, parseISO } from 'date-fns'
import { Bell, TriangleAlertIcon } from 'lucide-react'
import { memo } from 'react'

type CellPopoverProps = {
    data: Resultado
}

const LAUDO_CLASSNAME: Record<Resultado['laudo'], string> = {
    'NÃO CONFORME': 'text-red-500',
    CONFORME: 'text-emerald-600',
    'NÃO AVALIADO': 'text-muted-foreground',
}

const CellPopover = ({ data }: CellPopoverProps) => {
    const {
        valor,
        lie,
        lse,
        laudo,
        nome,
        data_da_coleta,
        hora_da_coleta,
        alteracao,
        data_resultado,
        hora_resultado,
        observacao_amostra,
        observacao_resultado,
        abreviatura_um,
        cod_amostra,
        cod_amostra_reanalise,
        status_correcao,
        observacao_correcao,
    } = data

    const isUpdating = status_correcao == '0'

    return (
        <HoverCard>
            <HoverCardTrigger
                className={cn(
                    'w-full h-full flex flex-1 overflow-hidden items-center gap-2 p-2 whitespace-nowrap last:border-0 border-r',
                    !isUpdating && laudo === 'NÃO CONFORME'
                        ? 'bg-destructive text-destructive-foreground'
                        : '',
                    !isUpdating && laudo === 'CONFORME'
                        ? 'dark:bg-emerald-500/80 bg-emerald-400/80 text-emerald-900 dark:text-emerald-800'
                        : '',
                    isUpdating && 'bg-amber-600'
                )}
            >
                {alteracao ? (
                    <Bell
                        size={12}
                        className={cn(
                            laudo === 'CONFORME' &&
                                'dark:text-white text-emerald-800',
                            laudo === 'NÃO CONFORME' && 'text-white'
                        )}
                    />
                ) : cod_amostra_reanalise ? (
                    <TriangleAlertIcon size={12} />
                ) : null}
                <TypographyP
                    className={cn(
                        'flex-1 overflow-hidden text-ellipsis',
                        laudo === 'NÃO CONFORME' &&
                            'text-destructive-foreground',
                        laudo === 'CONFORME' &&
                            'text-emerald-900 dark:text-foreground'
                    )}
                >
                    {valor} {abreviatura_um}
                </TypographyP>
            </HoverCardTrigger>
            <HoverCardPortal container={document.body}>
                <HoverCardContent>
                    <div className="space-y-2 text-center">
                        <div className="flex items-center justify-center gap-4">
                            <div className="flex flex-col items-center">
                                <TypographyMutedXs className="flex justify-center ">
                                    Amostra
                                </TypographyMutedXs>
                                <TypographyP className="flex justify-center items-center gap-0.5">
                                    {alteracao && !cod_amostra_reanalise ? (
                                        <EditedValuePopover
                                            value={cod_amostra}
                                        />
                                    ) : (
                                        <span>{cod_amostra}</span>
                                    )}
                                </TypographyP>
                            </div>
                            {cod_amostra_reanalise && (
                                <div className="flex flex-col items-center">
                                    <TypographyMutedXs className="flex items-center justify-center gap-1">
                                        <TriangleAlertIcon size={12} />
                                        Reanálise
                                    </TypographyMutedXs>
                                    <TypographyP className="flex justify-center items-center gap-0.5">
                                        {alteracao ? (
                                            <EditedValuePopover
                                                value={cod_amostra_reanalise}
                                                isReanalisis
                                            />
                                        ) : (
                                            <span
                                                className={cn(
                                                    cod_amostra_reanalise &&
                                                        'text-amber-600'
                                                )}
                                            >
                                                {cod_amostra_reanalise}
                                            </span>
                                        )}
                                    </TypographyP>
                                </div>
                            )}
                        </div>
                        <div className="flex items-center justify-center gap-2">
                            <div>
                                <TypographyMutedXs>LIE</TypographyMutedXs>
                                <TypographyP>
                                    {lie || '-'} {lie && abreviatura_um}
                                </TypographyP>
                            </div>
                            <div>
                                <TypographyMutedXs>LSE</TypographyMutedXs>
                                <TypographyP>
                                    {lse || '-'} {lse && abreviatura_um}
                                </TypographyP>
                            </div>
                        </div>
                        <div>
                            {isUpdating ? (
                                <TypographyP className="text-amber-600">
                                    AGUARDANDO CORREÇÃO
                                </TypographyP>
                            ) : (
                                <TypographyP
                                    className={cn(LAUDO_CLASSNAME[laudo])}
                                >
                                    {laudo || '-'}
                                </TypographyP>
                            )}
                        </div>
                        <Separator />
                        <div>
                            <TypographyMutedXs>
                                Data/Horário coleta
                            </TypographyMutedXs>
                            <TypographyP>
                                {format(parseISO(data_da_coleta), 'dd/MM/yyyy')}{' '}
                                - {hora_da_coleta}
                            </TypographyP>
                        </div>
                        <div>
                            <TypographyMutedXs>
                                Data/Horário resultado
                            </TypographyMutedXs>
                            <TypographyP>
                                {format(parseISO(data_resultado), 'dd/MM/yyyy')}{' '}
                                - {hora_resultado}
                            </TypographyP>
                        </div>
                        <div>
                            <TypographyMutedXs>
                                Observação amostra
                            </TypographyMutedXs>
                            <TypographyP>
                                {observacao_amostra || '-'}
                            </TypographyP>
                        </div>
                        <div>
                            <TypographyMutedXs>
                                Observação resultado
                            </TypographyMutedXs>
                            <TypographyP>
                                {observacao_resultado || '-'}
                            </TypographyP>
                        </div>
                        {observacao_correcao && (
                            <div>
                                <TypographyMutedXs className="text-amber-500">
                                    Observação correção
                                </TypographyMutedXs>
                                <TypographyP>
                                    {observacao_correcao || '-'}
                                </TypographyP>
                            </div>
                        )}
                        <div>
                            <TypographyMutedXs>Publicado por</TypographyMutedXs>
                            <TypographyP>{nome}</TypographyP>
                        </div>
                    </div>
                </HoverCardContent>
            </HoverCardPortal>
        </HoverCard>
    )
}

export default memo(CellPopover)
