import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorProvaHoraria } from '@/api/business/provaHoraria/type'
import CellPopover from './components/CellPopover'
import { TypographyMutedXs, TypographyXS } from '@/components/ui/typography'
import { cn } from '@/lib/utils'

type ProvaHorariaTableProps = {
    data?: MonitorProvaHoraria[]
}

const ProvaHorariaTable = ({ data = [] }: ProvaHorariaTableProps) => {
    const tableData = useMemo(() => {
        const uniqueValues = [
            ...new Set(
                data.flatMap((value) =>
                    value.resultados.map((result) => result.ensaio)
                )
            ),
        ]

        const newData = uniqueValues.map((ensaio) => {
            const row = {
                ensaio,
                data: data.filter((value) =>
                    value.resultados.some((result) => result.ensaio === ensaio)
                ),
            }

            return row
        })

        return newData
    }, [data])

    const tableColumnHeaders = useMemo(() => {
        return [
            ...new Set(
                data.map(
                    (value) =>
                        `${value.data_da_coleta}${value.hora_da_coleta}${value.cod_skip_lote}`
                )
            ),
        ].reduce((acc, curr) => {
            const found = data.filter(
                (value) =>
                    `${value.data_da_coleta}${value.hora_da_coleta}${value.cod_skip_lote}` ===
                    curr
            )

            return {
                ...acc,
                [curr]: found,
            }
        }, {} as Record<string, MonitorProvaHoraria[]>)
    }, [data])

    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    type ProvaHorariaTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<ProvaHorariaTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
                cell: ({ row, getValue }) => {
                    const { ensaio, data } = row.original

                    const isExtra = data.some((value) =>
                        value.resultados.some(
                            (result) =>
                                result.ensaio === ensaio &&
                                !result.cod_especificacao
                        )
                    )
                    return (
                        <div className="flex gap-1">
                            {getValue()}
                            <span className="text-md text-amber-600">
                                {isExtra && '*'}
                            </span>
                        </div>
                    )
                },
            }),
            ...Object.keys(tableColumnHeaders).map((header) => {
                const headerValue = data.find(
                    (value) =>
                        `${value.data_da_coleta}${value.hora_da_coleta}${value.cod_skip_lote}` ===
                        header
                )

                const isEnchedora = headerValue?.resultados.some((result) =>
                    result.skip_lote.includes('ENCHEDORA')
                )
                const isLinha = headerValue?.resultados.some((result) =>
                    result.skip_lote.includes('LINHA')
                )

                return columnHelper.display({
                    id: header.toString(),
                    header: () => {
                        const commonClassName = cn(
                            'overflow-hidden text-ellipsis',
                            isEnchedora || isLinha ? 'text-white' : ''
                        )

                        return (
                            <div className="flex flex-col space-y-0.5 overflow-hidden">
                                <TypographyXS className={cn(commonClassName)}>
                                    {headerValue?.abreviatura_produto}
                                </TypographyXS>
                                <TypographyXS className={cn(commonClassName)}>
                                    {headerValue?.numero_de_controle}
                                </TypographyXS>
                                <TypographyMutedXs
                                    className={cn(
                                        'overflow-hidden font-normal text-ellipsis',
                                        isEnchedora || isLinha
                                            ? 'text-white'
                                            : ''
                                    )}
                                >
                                    {headerValue?.data_da_coleta
                                        .split('-')
                                        .reverse()
                                        .join('/')}
                                </TypographyMutedXs>
                                <TypographyXS className={cn(commonClassName)}>
                                    {headerValue?.hora_da_coleta}
                                </TypographyXS>
                            </div>
                        )
                    },
                    cell: ({ row }) => {
                        const { ensaio } = row.original

                        return tableColumnHeaders[header].map((result) => {
                            return result.resultados
                                .filter((res) => res.ensaio === ensaio)
                                .map((res) => {
                                    return (
                                        <CellPopover
                                            header={result}
                                            data={res}
                                        />
                                    )
                                })
                        })
                    },
                    size: 175,
                    meta: {
                        header: {
                            className: cn(
                                isEnchedora &&
                                    'bg-[#cd9b29f1] dark:bg-[#a08310f2]',
                                isLinha && 'bg-sky-600 dark:bg-sky-700'
                            ),
                            menu: {
                                className: cn(
                                    isEnchedora &&
                                        'bg-transparent hover:bg-[#b48822f1] dark:hover:bg-[#856e13ef]',
                                    isLinha &&
                                        'bg-xadrez dark:bg-sky-700 hover:bg-sky-700 dark:hover:bg-sky-800'
                                ),
                            },
                        },
                        cell: {
                            className: 'p-0',
                        },
                    },
                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<ProvaHorariaTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
        />
    )
}

export default ProvaHorariaTable
