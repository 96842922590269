import { useNavigate } from 'react-router-dom'

import { ROUTES, Route } from '@/consts/routes'

interface QueryParams {
    [key: string]: string | number | boolean | undefined
}

const routes = Object.values(ROUTES)

export const useRouteNavigator = () => {
    const navigate = useNavigate()

    const navigateTo = (target: string, queryParams?: QueryParams): void => {
        const route = findRouteByIndex(routes, target)
        let fullPath = route
            ? route.getRoutePath()
            : target.split('?')[0].replace(/\/$/, '')
        if (queryParams) {
            const searchParams = new URLSearchParams(
                queryParams as any
            ).toString()
            fullPath += `?${searchParams}`
        }
        navigate(fullPath)
    }

    return navigateTo
}

export const findRouteByIndex = (
    routes: Route[],
    path: string
): Route | undefined => {
    const cleanPath = path.split('?')[0].replace(/\/$/, '')

    return routes.find((route) => {
        const normalizedRoutePath = route.getRoutePath().replace(/\/$/, '')
        return route.index === cleanPath || normalizedRoutePath === cleanPath
    })
}
export const findChildren = (routes: Route[], parentIndex: string): Route[] => {
    return routes.filter((route) => route.getParentRoute() === parentIndex)
}
