import { useMonitorBrassagemQuery } from '@/queries/useMonitorBrassagemQuery'
import { format, isBefore } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'
import { SALAS } from './consts'
import {
    TypographyLarge,
    TypographyMutedXs,
    TypographyXS,
} from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { DatePicker } from '@/components/DatePicker'
import CompanySelect from '../../components/CompanySelect'
import BrassagemTable from './components/BrassagemTable'
import {
    CalendarX2,
    Maximize,
    Minimize,
    RefreshCcw,
    Search,
} from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import LayoutSheet from './components/LayoutSheet'
import { CheckState } from '@/store/monitorBrassagemSlice/type'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { Resultado } from '@/api/business/brassagem/type'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { useFullscreen } from '@/hooks/useFullscreen'
import { Button } from '@/components/ui/button'
import { toast } from 'sonner'
import { isBetween } from '@/utils/date'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorBrassagemSlice.state.selectedCompany,
    date: state.monitorBrassagemSlice.state.date,
    selectedSala: state.monitorBrassagemSlice.state.selectedSala,
    tableLayoutState: state.monitorBrassagemSlice.state.tableLayoutState,
    setSala: state.monitorBrassagemSlice.actions.setSala,
    setCompany: state.monitorBrassagemSlice.actions.setCompany,
    setDateInterval: state.monitorBrassagemSlice.actions.setDateInterval,
    setTableLayout: state.monitorBrassagemSlice.actions.setTableLayout,
    resetDate: state.monitorBrassagemSlice.actions.resetDate,
})

const ORDER: Record<Resultado['operacao'], number> = {
    MOSTURAÇÃO: 1,
    SACARIFICAÇÃO: 2,
    FILTRAÇÃO: 3,
    'RINSAGEM DE TINA': 4,
    'FINAL DE FILTRAÇÃO': 5,
    'INICIO DE FERVURA': 6,
    'FINAL DE FERVURA': 7,
    'RESFRIAMENTO DO MOSTO': 8,
}

const MonitorBrassagem = () => {
    const {
        selectedCompany,
        date: { initial, final },
        tableLayoutState,
        selectedSala,
        setDateInterval,
        setSala,
        setCompany,
        setTableLayout,
    } = useBaseStore(stateSelector)

    const [{ from, to }, setDate] = useState({
        from: new Date(initial) || new Date(),
        to: new Date(final) || new Date(),
    })

    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const { data, isPending, isFetching, isError, dataUpdatedAt, refetch } =
        useMonitorBrassagemQuery({
            cod_filial: selectedCompany,
            data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
            data_final: Number(format(new Date(final), 'yyyyMMdd')),
            sala_de_brassagem: selectedSala,
            enabled: isEmpresasSuccess && empresas.length > 0,
        })

    const onChangeDate = (date: Date, type: 'from' | 'to') => {
        setDate((prev) => ({
            ...prev,
            [type]: date,
        }))
    }

    const onResetDate = () => {
        setDate({
            from: new Date(),
            to: new Date(),
        })
    }

    const onSearch = () => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    const operacoes = useMemo(
        () =>
            data
                ? [
                      ...new Set(
                          data
                              .flatMap((value) =>
                                  value.resultados.map(
                                      (result) => result.operacao
                                  )
                              )
                              .sort((a, b) => {
                                  return ORDER[a] > ORDER[b] ? 1 : -1
                              })
                      ),
                  ].reduce((acc, curr) => {
                      const found = data
                          .flatMap((value) => value.resultados)
                          .filter((result) => result.operacao === curr)

                      return {
                          ...acc,
                          [curr]: [
                              ...new Set(found.map((value) => value.ensaio)),
                          ],
                      }
                  }, {} as Record<string, string[]>)
                : {},
        [data]
    )

    useEffect(() => {
        setTableLayout(
            Object.entries(operacoes).reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr[0]]: curr[1].reduce((acc, curr) => {
                        return {
                            ...acc,
                            [curr]: true,
                        }
                    }, {}),
                }
            }, {} as CheckState)
        )
    }, [operacoes])

    return (
        <PageContainer ref={mainContainerRef}>
            <div className="absolute top-4 right-4">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex items-center justify-center gap-8 p-8">
                {SALAS.map((sala) => {
                    const isSelected = selectedSala === sala.value

                    return (
                        <div key={sala.value}>
                            <input
                                type="radio"
                                name="sala"
                                value={sala.value}
                                id={sala.value}
                                className="hidden"
                                onChange={(e) => {
                                    setSala(e.target.value)
                                }}
                            />
                            <TypographyLarge asChild>
                                <label
                                    htmlFor={sala.value}
                                    className={cn(
                                        'cursor-pointer',
                                        isSelected
                                            ? 'text-primary'
                                            : 'text-accent-foreground'
                                    )}
                                >
                                    {sala.label}
                                </label>
                            </TypographyLarge>
                        </div>
                    )
                })}
            </div>
            <div className="flex justify-between px-4 pb-2">
                <div>
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany}
                        onChange={setCompany}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-4">
                        <Button variant="ghost" size="icon" onClick={onSearch}>
                            <Search size={16} />
                        </Button>
                        <DatePicker
                            date={from}
                            setDate={(date) => {
                                if (date) {
                                    onChangeDate(date, 'from')
                                }
                            }}
                        />
                        <DatePicker
                            date={to}
                            setDate={(date) => {
                                if (date) {
                                    onChangeDate(date, 'to')
                                }
                            }}
                        />
                    </div>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => onResetDate()}
                        tooltipContent={
                            <TypographyXS>Resetar data</TypographyXS>
                        }
                    >
                        <CalendarX2 size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={
                            <TypographyXS>Atualizar dados</TypographyXS>
                        }
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            <TypographyXS>
                                {isFullscreen ? 'Minimizar' : 'Maximizar'}
                            </TypographyXS>
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                    <LayoutSheet
                        columns={operacoes}
                        checkState={tableLayoutState}
                    />
                </div>
            </div>
            <div className="flex-1 px-4 py-2 overflow-hidden">
                <AnimatePresence>
                    {!data && isPending ? (
                        <TopMessage
                            variant="loading"
                            text="Carregando dados..."
                        />
                    ) : null}
                    {!isPending && isFetching ? (
                        <TopMessage
                            variant="success"
                            text="Atualizando dados..."
                        />
                    ) : null}
                    {isError && (
                        <TopMessage
                            variant="error"
                            text="Erro ao buscar dados"
                        />
                    )}
                </AnimatePresence>
                <BrassagemTable
                    data={data}
                    operacoes={operacoes}
                    checkState={tableLayoutState}
                />
            </div>
        </PageContainer>
    )
}

export default MonitorBrassagem
