import {
    fetchMonitorFermentacao,
    fetchMonitorFermentacaoFiltros,
} from '@/api/business/fermentacao'
import {
    FetchMonitorFermentacaoFiltrosProps,
    FetchMonitorFermentacaoProps,
} from '@/api/business/fermentacao/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const fermentacaoKeys = {
    all: ['fermentacao'] as const,
    lists: () => [...fermentacaoKeys.all, 'list'] as const,
    list: (props: FetchMonitorFermentacaoProps) =>
        [...fermentacaoKeys.lists(), { ...props }] as const,
    details: () => [...fermentacaoKeys.all, 'detail'] as const,
    detail: (params: string) => [...fermentacaoKeys.details(), params] as const,
    filters: ({ cod_filial }: FetchMonitorFermentacaoFiltrosProps) =>
        [...fermentacaoKeys.lists(), 'filters', cod_filial] as const,
}

export type UseFermentacaoQueryProps = FetchMonitorFermentacaoProps & {
    enabled: boolean
}

export type UseFermentacaoFiltroQueryProps =
    FetchMonitorFermentacaoFiltrosProps & {
        enabled: boolean
    }

export const useMonitorFermentacaoFiltrosQuery = ({
    enabled,
    ...props
}: UseFermentacaoFiltroQueryProps) => {
    return useQuery({
        queryKey: fermentacaoKeys.filters({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorFermentacaoFiltros({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) =>
            data.sort((a, b) => a.abreviatura.localeCompare(b.abreviatura)),
    })
}

export const useMonitorFermentacaoQuery = (props: UseFermentacaoQueryProps) => {
    return useQuery({
        queryKey: fermentacaoKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorFermentacao({ ...props }),
        placeholderData: keepPreviousData,
    })
}
