import {
    fetchMonitorMaturacao,
    fetchMonitorMaturacaoFiltros,
} from '@/api/business/maturacao'
import {
    FetchMonitorMaturacaoFiltrosProps,
    FetchMonitorMaturacaoProps,
} from '@/api/business/maturacao/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const maturacaoKeys = {
    all: ['maturacao'] as const,
    lists: () => [...maturacaoKeys.all, 'list'] as const,
    list: (props: FetchMonitorMaturacaoProps) =>
        [...maturacaoKeys.lists(), { ...props }] as const,
    details: () => [...maturacaoKeys.all, 'detail'] as const,
    detail: (params: string) => [...maturacaoKeys.details(), params] as const,
    filters: ({ cod_filial }: FetchMonitorMaturacaoFiltrosProps) =>
        [...maturacaoKeys.lists(), 'filters', cod_filial] as const,
}

export type UseMaturacaoQueryProps = FetchMonitorMaturacaoProps & {
    enabled: boolean
}

export type UseMaturacaoFiltroQueryProps = FetchMonitorMaturacaoFiltrosProps & {
    enabled: boolean
}

export const useMonitorMaturacaoFiltrosQuery = ({
    enabled,
    ...props
}: UseMaturacaoFiltroQueryProps) => {
    return useQuery({
        queryKey: maturacaoKeys.filters({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorMaturacaoFiltros({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) =>
            data.sort((a, b) => a.abreviatura.localeCompare(b.abreviatura)),
    })
}

export const useMonitorMaturacaoQuery = (props: UseMaturacaoQueryProps) => {
    return useQuery({
        queryKey: maturacaoKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorMaturacao({ ...props }),
        placeholderData: keepPreviousData,
    })
}
