import { EMPRESAS } from '@/consts/companies'
import { ImmerStateCreator } from '../type'
import {
    MonitorProdutosAcabadosSlice,
    MonitorProdutosAcabadosState,
} from './type'
import { SALAS } from '@/pages/MonitorProdutoAcabado/consts'

const initialState: MonitorProdutosAcabadosState = {
    selectedCompany: EMPRESAS[0].value,
    selectedSala: SALAS[0].value,
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
    selectedLinha: '',
    selectedProduto: '',
}

const persist = (state: MonitorProdutosAcabadosState) => {
    localStorage.setItem('monitor-produtos-acabados', JSON.stringify(state))
}

const getInicialState = () => {
    try {
        const storage = localStorage.getItem('monitor-produtos-acabados')

        if (storage) {
            return JSON.parse(storage)
        }

        return initialState
    } catch (error) {
        console.log(error)
        return initialState
    }
}

export const useMonitorProdutosAcabadosSlice: ImmerStateCreator<
    MonitorProdutosAcabadosSlice
> = (set) => ({
    state: getInicialState(),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.selectedCompany =
                    company

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.date.initial = initial
                state.monitorProdutosAcabadosSlice.state.date.final = final

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.selectedSala = sala

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        setLinha: (linha) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.selectedLinha = linha

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        setProduto: (produto) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.selectedProduto =
                    produto

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state = initialState
            })
        },
    },
})
