import instance from '..'
import {
    FetchMonitorFermentacaoFiltrosProps,
    FetchMonitorFermentacaoProps,
    MonitorFermentacao,
    MonitorFermentacaoFiltros,
} from './type'

export const fetchMonitorFermentacao = async (
    props: FetchMonitorFermentacaoProps
) => {
    const response = await instance.post<MonitorFermentacao[]>(
        '/monitor/fermentacao',
        {
            ...props,
        }
    )

    return response.data
}

export const fetchMonitorFermentacaoFiltros = async (
    props: FetchMonitorFermentacaoFiltrosProps
) => {
    const response = await instance.post<MonitorFermentacaoFiltros[]>(
        '/monitor/recursos/fermentacao',
        {
            ...props,
        }
    )

    return response.data
}
