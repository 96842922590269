import { EMPRESAS } from '@/consts/companies'
import { ImmerStateCreator } from '../type'
import { MonitorFiltracaoSlice, MonitorFiltracaoState } from './type'
import { SALAS } from '@/pages/MonitorProdutoAcabado/consts'

const initialState: MonitorFiltracaoState = {
    selectedCompany: EMPRESAS[0].value,
    selectedSala: SALAS[0].value,
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const persist = (state: MonitorFiltracaoState) => {
    localStorage.setItem('monitor-filtracao', JSON.stringify(state))
}

const getInicialState = () => {
    try {
        const storage = localStorage.getItem('monitor-filtracao')

        if (storage) {
            return JSON.parse(storage)
        }

        return initialState
    } catch (error) {
        console.log(error)
        return initialState
    }
}

export const useMonitorFiltracaoSlice: ImmerStateCreator<
    MonitorFiltracaoSlice
> = (set) => ({
    state: getInicialState(),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorFiltracaoSlice.state.selectedCompany = company

                persist(state.monitorFiltracaoSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorFiltracaoSlice.state.date.initial = initial
                state.monitorFiltracaoSlice.state.date.final = final

                persist(state.monitorFiltracaoSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorFiltracaoSlice.state.selectedSala = sala

                persist(state.monitorFiltracaoSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorFiltracaoSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorFiltracaoSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorFiltracaoSlice.state = initialState
            })
        },
    },
})
