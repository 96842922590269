import instance from '..'
import {
    EmptyReturn,
    FetchMonitorRastreabilidadeProps,
    MonitorRastreabilidade,
    MonitorRastreabilidadeAPIReturn,
} from './type'

const BASE_URL = '/monitor/rastreio'

export const fetchMonitorRastreabilidade = async (
    props: FetchMonitorRastreabilidadeProps
) => {
    const response = await instance.post<MonitorRastreabilidadeAPIReturn>(
        BASE_URL,
        {
            ...props,
        }
    )

    if ((response.data as EmptyReturn).body) {
        return []
    } else {
        return [response.data] as unknown as MonitorRastreabilidade[]
    }
}
