import { useAsideContext } from '@/context/AsideContext'
import { useEffect, useRef } from 'react'
import { ResizableHandle, ResizablePanel } from '../ui/resizable'

const AsidePortalContainer = () => {
    const ref = useRef<HTMLDivElement>(null)

    const { setContainerRef, isOpen } = useAsideContext()

    useEffect(() => {
        setContainerRef(ref)
    }, [])

    return (
        <>
            {isOpen && <ResizableHandle />}
            <ResizablePanel minSize={isOpen ? 20 : 0} maxSize={isOpen ? 30 : 0}>
                <div
                    id="aside-portal-container"
                    ref={ref}
                    className="w-full h-full"
                />
            </ResizablePanel>
        </>
    )
}

export default AsidePortalContainer
