import { fetchEmpresas } from '@/api/business/empresa'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const empresaKeys = {
    all: ['empresa'] as const,
    lists: () => [...empresaKeys.all, 'list'] as const,
}

export const useEmpresaQuery = () => {
    return useQuery({
        queryKey: empresaKeys.lists(),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchEmpresas(),
        placeholderData: keepPreviousData,
    })
}
