import { NavLink } from 'react-router-dom'
import { cn } from '@/lib/utils'
import { Route } from '@/consts/routes'

type SidenavItemProps = Route & {
    onOpenChange: (isOpen: boolean) => void
}

const SidenavItem = ({
    icon: Icon,
    index,
    label,
    getRoutePath,
    onOpenChange,
}: SidenavItemProps) => {
    return (
        <NavLink
            key={index}
            to={getRoutePath()}
            end
            className={({ isActive }) =>
                cn(
                    'flex items-center text-sm gap-4 px-4 py-2 rounded-sm hover:bg-accent ',
                    isActive
                        ? 'text-primary-600 font-semibold dark:text-primary'
                        : 'text-muted-foreground hover:text-foreground'
                )
            }
            onClick={() => {
                onOpenChange(false)
            }}
        >
            {Icon && <Icon size={16} />}
            {label}
        </NavLink>
    )
}

export default SidenavItem
