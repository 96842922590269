import {
    Dispatch,
    ReactNode,
    RefObject,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'
import { createPortal } from 'react-dom'

type AsideContextState = {
    isOpen: boolean
    containerRef: RefObject<HTMLDivElement> | null
    setIsOpen: Dispatch<React.SetStateAction<boolean>>
    setContainerRef: (ref: RefObject<HTMLDivElement>) => void
}

type AsideContextProviderProps = {
    children?: ReactNode
}

type AsidePortalProps = {
    children?: ReactNode
    initialOpen?: boolean
}

const initialValue = {
    containerRef: null,
    isOpen: true,
}

const AsideContext = createContext<AsideContextState>(
    initialValue as AsideContextState
)

export const AsideContextProvider = ({
    children,
}: AsideContextProviderProps) => {
    const [containerRef, setContainerRef] = useState<RefObject<HTMLDivElement>>(
        null as unknown as RefObject<HTMLDivElement>
    )
    const [isOpen, setIsOpen] = useState(false)

    return (
        <AsideContext.Provider
            value={{ containerRef, isOpen, setIsOpen, setContainerRef }}
        >
            {children}
        </AsideContext.Provider>
    )
}

export const AsidePortal = ({ children, initialOpen }: AsidePortalProps) => {
    const { containerRef, setIsOpen } = useAsideContext()

    useEffect(() => {
        setIsOpen(!!initialOpen)
    }, [initialOpen])

    if (!containerRef?.current) return null

    return createPortal(children, containerRef?.current)
}

export const useAsideContext = () => useContext(AsideContext)
