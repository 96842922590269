import Button from '@/components/Button'
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from '@/components/ui/sheet'
import { Eye, EyeOff, LayoutDashboard } from 'lucide-react'
import { TypographyP, TypographyXS } from '@/components/ui/typography'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { cn } from '@/lib/utils'
import { CheckState } from '@/store/monitorBrassagemSlice/type'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'

type LayoutSheetProps = {
    columns: Record<string, string[]>
    checkState: CheckState
}

const stateSelector = (state: Store) => ({
    tableLayoutState: state.monitorBrassagemSlice.state.tableLayoutState,
    toggleTableLayout: state.monitorBrassagemSlice.actions.toggleTableLayout,
})

const LayoutSheet = ({ columns }: LayoutSheetProps) => {
    const { tableLayoutState, toggleTableLayout } = useBaseStore(stateSelector)
    return (
        <Sheet>
            <SheetTrigger asChild>
                <ButtonWithTooltip
                    variant="ghost"
                    size="icon"
                    tooltipContent={<TypographyXS>Layout</TypographyXS>}
                >
                    <LayoutDashboard size={14} />
                </ButtonWithTooltip>
            </SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Layout tabela</SheetTitle>
                </SheetHeader>
                <div className="py-4 space-y-2">
                    {Object.entries(columns).map(([operacao, ensaios]) => {
                        if (!tableLayoutState[operacao]) return null

                        const ensaiosValues = Object.values(
                            tableLayoutState[operacao]
                        )

                        const checkedLength =
                            ensaiosValues.filter(Boolean).length

                        const isAllChecked = ensaios.length === checkedLength

                        const isIndeterminate =
                            isAllChecked || checkedLength > 0

                        return (
                            <div key={operacao}>
                                <div className="flex items-center gap-2">
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        onClick={() =>
                                            toggleTableLayout({
                                                operacao,
                                                value: !isIndeterminate,
                                            })
                                        }
                                    >
                                        {isIndeterminate ? (
                                            <Eye size={14} />
                                        ) : (
                                            <EyeOff size={14} />
                                        )}
                                    </Button>

                                    <TypographyP
                                        className={cn(
                                            !isIndeterminate &&
                                                'text-muted-foreground'
                                        )}
                                    >
                                        {operacao}
                                    </TypographyP>
                                </div>
                                {ensaios.map((ensaio) => {
                                    const isChecked =
                                        tableLayoutState[operacao][ensaio]

                                    return (
                                        <div
                                            key={`${operacao}${ensaio}`}
                                            className="flex items-center gap-4 py-2 pl-12"
                                        >
                                            <Checkbox
                                                id={`${operacao}${ensaio}`}
                                                checked={isChecked}
                                                onCheckedChange={(value) => {
                                                    toggleTableLayout({
                                                        operacao,
                                                        ensaio,
                                                        value: !!value,
                                                    })
                                                }}
                                            />
                                            <Label
                                                className={cn(
                                                    isChecked
                                                        ? 'text-foreground'
                                                        : 'text-muted-foreground'
                                                )}
                                                htmlFor={`${operacao}${ensaio}`}
                                            >
                                                {ensaio}
                                            </Label>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </SheetContent>
        </Sheet>
    )
}

export default LayoutSheet
