import { MonitorFermentacao } from '@/api/business/fermentacao/type'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyXS } from '@/components/ui/typography'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'

type LotesSelectProps = {
    data?: MonitorFermentacao[]
    selectedId: string
    onChange: (value: string) => void
}

const LotesSelect = ({ data, selectedId, onChange }: LotesSelectProps) => {
    return (
        <Select value={selectedId} onValueChange={onChange}>
            <SelectTrigger className="hover:bg-muted h-[56px] min-w-[200px]">
                <SelectValue
                    className="text-start"
                    placeholder="Selecione um lote"
                />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {data?.map((value) => {
                        return (
                            <SelectItem
                                key={value.lote_de_controle_de_qualidade}
                                value={value.lote_de_controle_de_qualidade}
                            >
                                <div className="flex flex-col p-1 space-y-1">
                                    <TypographyXS className="text-start">
                                        {value.lote_de_controle_de_qualidade}
                                    </TypographyXS>
                                    <TypographyXS className="text-start text-muted-foreground">
                                        {format(
                                            getDateFromId(value.data),
                                            'dd/MM/yyyy'
                                        )}
                                    </TypographyXS>
                                </div>
                            </SelectItem>
                        )
                    })}
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}

export default LotesSelect
