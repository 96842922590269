import {
    fetchMonitorArrolhamento,
    fetchMonitorArrolhamentoFiltros,
} from '@/api/business/arrolhamento'
import {
    FetchMonitorArrolhamentoFiltrosProps,
    FetchMonitorArrolhamentoProps,
    MonitorArrolhamento,
} from '@/api/business/arrolhamento/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const arrolhamentoKeys = {
    all: ['arrolhamento'] as const,
    lists: () => [...arrolhamentoKeys.all, 'list'] as const,
    list: (props: FetchMonitorArrolhamentoProps) =>
        [...arrolhamentoKeys.lists(), { ...props }] as const,
    details: () => [...arrolhamentoKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...arrolhamentoKeys.details(), params] as const,
    filters: ({ cod_filial }: FetchMonitorArrolhamentoFiltrosProps) =>
        [...arrolhamentoKeys.lists(), 'filters', cod_filial] as const,
}

export type UseArrolhamentoQueryProps = FetchMonitorArrolhamentoProps & {
    enabled: boolean
}

export type UseArrolhamentoFiltroQueryProps =
    FetchMonitorArrolhamentoFiltrosProps & {
        enabled: boolean
    }

export const useMonitorArrolhamentoFiltrosQuery = ({
    enabled,
    ...props
}: UseArrolhamentoFiltroQueryProps) => {
    return useQuery({
        queryKey: arrolhamentoKeys.filters({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorArrolhamentoFiltros({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) =>
            data.sort((a, b) =>
                a.centro_de_custo.localeCompare(b.centro_de_custo)
            ),
    })
}

export const useMonitorArrolhamentoQuery = (
    props: UseArrolhamentoQueryProps
) => {
    return useQuery({
        queryKey: arrolhamentoKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorArrolhamento({ ...props }),
        placeholderData: keepPreviousData,
        select: (data): MonitorArrolhamento[] =>
            data.map((value) => ({
                ...value,
                turnos: value.turnos
                    .sort((a, b) => a.turno.localeCompare(b.turno))
                    .map((turno) => {
                        return {
                            ...turno,
                            resultados: turno.abreviatura_bem.map((bem) => {
                                return {
                                    ...bem,
                                    abreviatura_arrolhador:
                                        bem.abreviatura_arrolhador.sort(
                                            (a, b) =>
                                                a.abreviatura_arrolhador.localeCompare(
                                                    b.abreviatura_arrolhador
                                                )
                                        ),
                                }
                            }),
                        }
                    }),
            })),
    })
}
