import { MonitorMaturacaoFiltros } from '@/api/business/maturacao/type'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyXS } from '@/components/ui/typography'

type LinhasSelectProps = {
    data?: MonitorMaturacaoFiltros[]
    selectedId: string
    onChange: (value: string) => void
}

const LinhasSelect = ({ data, selectedId, onChange }: LinhasSelectProps) => {
    return (
        <Select value={selectedId} onValueChange={onChange}>
            <SelectTrigger className="hover:bg-muted h-[56px] min-w-[200px]">
                <SelectValue
                    className="text-start"
                    placeholder="Selecione um tanque"
                />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {data?.map((value) => {
                        return (
                            <SelectItem
                                key={value.cod_bem}
                                value={value.cod_bem.toString()}
                            >
                                <div className="flex flex-col p-1 space-y-1">
                                    <TypographyXS className="text-start">
                                        {value.abreviatura}
                                    </TypographyXS>
                                </div>
                            </SelectItem>
                        )
                    })}
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}

export default LinhasSelect
