import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar, CalendarProps } from '@/components/ui/calendar'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { ptBR } from 'date-fns/locale'

type DatepickerProps = {
    date?: Date
    setDate: (date: Date | undefined) => void
} & CalendarProps

export const DatePicker = ({ date, setDate, ...props }: DatepickerProps) => {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    className={cn(
                        'w-[280px] justify-start text-left font-normal',
                        !date && 'text-muted-foreground',
                        props.className
                    )}
                >
                    <CalendarIcon className="w-4 h-4 mr-2" />
                    {date ? (
                        <p className="flex-1 overflow-hidden text-ellipsis">
                            {format(date, 'PPP', {
                                locale: props.locale || ptBR,
                            })}
                        </p>
                    ) : (
                        <span className="flex-1 overflow-hidden text-ellipsis">
                            Escolha uma data
                        </span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                    {...props}
                    locale={props.locale || ptBR}
                    mode="single"
                    selected={date}
                    onSelect={setDate}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
