import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import FlowMenuInput from './components/FlowMenuInput'
import { SearchState } from '@/store/monitorRastreabilidadeSlice/type'
import { useSearchParams } from 'react-router-dom'

const stateSelector = (state: Store) => ({
    cod_filial: state.monitorRastreabilidadeSlice.state.empresa,
    horaminuto: state.monitorRastreabilidadeSlice.state.horaminuto,
    linha: state.monitorRastreabilidadeSlice.state.linha,
    lote: state.monitorRastreabilidadeSlice.state.lote,
    setState: state.monitorRastreabilidadeSlice.actions.setState,
})
const FlowMenu = () => {
    const { cod_filial, horaminuto, linha, lote, setState } =
        useBaseStore(stateSelector)

    const [, setSearchParams] = useSearchParams()

    const onBlur = (key: keyof SearchState, value: string) => {
        setState(key, value)
        setSearchParams((search) => {
            search.set(key, value)
            return search
        })
    }

    return (
        <div className="w-[320px] border-l h-full bg-muted px-3 py-4">
            <div className="space-y-4">
                <FlowMenuInput
                    label="Empresa"
                    value={cod_filial}
                    onBlur={(value) => {
                        onBlur('empresa', value)
                    }}
                />
                <FlowMenuInput
                    label="Referência"
                    value={lote}
                    onBlur={(value) => {
                        onBlur('lote', value)
                    }}
                />
                <FlowMenuInput
                    label="Linha"
                    value={linha}
                    onBlur={(value) => {
                        onBlur('linha', value)
                    }}
                />
                <FlowMenuInput
                    label="Hora minuto"
                    value={horaminuto}
                    onBlur={(value) => {
                        onBlur('horaminuto', value)
                    }}
                />
            </div>
        </div>
    )
}

export default FlowMenu
