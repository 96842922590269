import { useEffect, useState } from 'react'

export type UseFullscreenProps = {
    element: HTMLElement | null
}

export const useFullscreen = ({ element }: UseFullscreenProps) => {
    const [isFullscreen, setIsFullscreen] = useState(false)

    const onToggleFullScreen = () => {
        document.fullscreenElement
            ? document.exitFullscreen()
            : element?.requestFullscreen()
    }

    useEffect(() => {
        const onFullScreenChange = () =>
            setIsFullscreen(!!document.fullscreenElement)

        if (element) {
            element.addEventListener('fullscreenchange', onFullScreenChange)
        }

        return () => {
            element?.removeEventListener('fullscreenchange', onFullScreenChange)
        }
    }, [])

    return {
        isFullscreen,
        onToggleFullScreen,
    }
}
